import Badge from '../../../../components/Badge';
import { IBadgeFilter, IFilter } from '../../FiltersTypes';
import { getBadgeText } from '../../FiltersUtil';

export const FiltersShownList = ({
  filtersShown,
  onEditFilter,
  onRemoveFilter,
  teamId,
}: {
  filtersShown: IFilter[];
  onEditFilter: any;
  onRemoveFilter: any;
  teamId: number;
}) => {
  return (
    <div className="flex flex-row flex-wrap items-center gap-x-1 gap-y-1 font-bold text-blueberry">
      {filtersShown.map((filter, index) => {
        const badgeValue: IBadgeFilter = { text: getBadgeText(filter), id: index.toString(), filter: filter };
        return (
          <div id="applied-filter" className="" key={index}>
            <Badge
              badge={badgeValue}
              key={index}
              onEdit={(badge: any) => {
                onEditFilter(badge as IBadgeFilter);
              }}
              smaller
              onRemove={(badge) => onRemoveFilter(badge as IBadgeFilter, teamId)}
              color="bg-blueberry"
              textColor="text-white"
             /*  color="bg-gray-300"
              textColor="text-blueberry-darker" */
              textFont="font-semibold"
              capitalize={false}
            />
          </div>
        );
      })}
    </div>
  );
};
