import Button, { ButtonVariant, ButtonShape } from '../../../baseComponents/Button';

export const BoardPreviewCardStaticSkeleton = ({ onButtonClick }: { onButtonClick: () => void }) => {
  return (
    <div data-testid="custom-chart-card-skeleton" className="custom-chart-card-skeleton rounded-lg border border-gray-200 relative">
      <div className="h-[44px] rounded-t-lg px-4 bg-silver flex flex-row items-center w-full">
        <div className="flex flex-row gap-x-3 items-center w-full">
          <div className="w-[107px] h-3 flex rounded-lg bg-gray-200"></div>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 bg-white justify-end pb-6 px-6 h-[164px] rounded-b-lg w-full">
        <div className="w-2/3 h-5 flex rounded-lg bg-silver"></div>
        <div className="w-1/3 h-5 flex rounded-lg bg-silver"></div>
        <Button variant={ButtonVariant.Primary} shape={ButtonShape.Pill} text="New Board +" onClick={onButtonClick}/>
      </div>
    </div>
  );
};
