import { useEffect, useState } from 'react';
import { Domain, OrganizationUser, Role, Role_Attachments, Users } from '../../../generated/graphql';
import { capitalizeFirstLetter, classNames, truncateAndEllipsis } from '../../../v2/util';
import { OrgTeamWithInfo } from '../../../v2/contexts/AppContext';
import { initializeRolesForUser, initializeRolesForView, PermissionsActionTypes } from '../../../reducers/orgInfo/permissions/permissionsReducer';
import { usePermissionsDispatch, usePermissionsState } from '../../../context/permissionsContext';
import Tippy from '@tippyjs/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

interface PermissionsEditorForAUserProps {
  views: OrgTeamWithInfo[];
  currentRoles: Role_Attachments[];
  isFiltered?: boolean;
}

interface PermissionsEditorForAViewProps {
  users: Users[];
  currentRoles: Role_Attachments[];
  isFiltered?: boolean;
}

interface RoleWithDescription {
  role: Role;
  description: string;
}
const availableRoles: RoleWithDescription[] = [
  { role: Role.Contributor, description: 'Can create and edit taxonomy, manage boards and charts, and correct AI decisions.' },
  { role: Role.Replier, description: 'Can send responses to customers if replying is enabled.' },
  { role: Role.Viewer, description: 'Can only view data and insights; no editing rights.' },
];

export const PermissionsEditorForAUser = ({ views, currentRoles, isFiltered }: PermissionsEditorForAUserProps) => {
  const { permissionsMap } = usePermissionsState();
  const dispatch = usePermissionsDispatch();

  useEffect(() => {
    //Showing one view per row, with the roles assigned to the current user.
    const initialMap: Record<number, Record<Role, boolean>> = views.reduce((acc, view) => {
      acc[view.id] = initializeRolesForView(view.id, currentRoles);
      return acc;
    }, {} as Record<number, Record<Role, boolean>>);
    dispatch({ type: PermissionsActionTypes.SetPermissions, payload: { permissionsMap: initialMap } });
  }, []);

  const handleCheckboxChange = (view: OrgTeamWithInfo, role: Role) => {
    dispatch({ type: PermissionsActionTypes.ToggleRole, payload: { id: view.id, role } });
  };

  const handleSelectAllChange = (role: Role, toggled: boolean) => {
    dispatch({ type: PermissionsActionTypes.ToggleAllForRole, payload: { role, toggled } });
  };

  return (
    <div className="w-full text-blueberry">
      <div className="flow-root">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="h-72 overflow-y-auto">
            {views.length > 0 ? (
              <table className="min-w-full table-fixed border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky w-1/3 top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 pr-3 text-left font-light text-xs italic text-gray-500 backdrop-blur backdrop-filter"
                    >
                      View
                    </th>
                    {availableRoles.map((role) => (
                      <th
                        key={role.role}
                        scope="col"
                        className="w-1/6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 text-left font-light text-xs italic text-gray-500 backdrop-blur backdrop-filter"
                      >
                        <Tippy content={role.description}>
                          <div className="flex flex-row select-none cursor-default items-center justify-center gap-x-1 w-fit">
                            {capitalizeFirstLetter(role.role)}

                            <QuestionMarkCircleIcon className="h-3.5 w-3.5 text-gray-400" />
                          </div>
                        </Tippy>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!isFiltered ? (
                    <tr className="bg-silver">
                      <td className="whitespace-nowrap pr-3 py-3 text-sm font-light italic text-gray-500 pl-2 border-b border-gray-300">Select all</td>
                      {availableRoles.map((role) => (
                        <td key={role.role} className="whitespace-nowrap px-3 py-3 text-xs text-gray-500 border-b border-gray-300">
                          <input
                            type="checkbox"
                            onChange={(e) => handleSelectAllChange(role.role, e.target.checked)}
                            checked={views.every((view) => permissionsMap?.[view.id]?.[role.role])}
                            className="h-4 w-4 text-blueberry border-gray-300 rounded focus:ring-0"
                          />
                        </td>
                      ))}
                    </tr>
                  ) : null}
                  {views.map((view, itemIdx) => (
                    <tr key={view.id}>
                      <td
                        title={view.name}
                        className={classNames(
                          itemIdx !== views.length - 1 ? 'border-b border-gray-300' : '',
                          'whitespace-nowrap pr-3 text-sm font-semibold pl-2'
                        )}
                      >
                        {truncateAndEllipsis(view.name, 30)}
                      </td>
                      {availableRoles.map((role) => (
                        <td
                          key={role.role}
                          className={classNames(
                            itemIdx !== views.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap px-3 py-3 text-xs text-gray-500'
                          )}
                        >
                          <input
                            type="checkbox"
                            checked={permissionsMap?.[view.id]?.[role.role]}
                            onChange={() => handleCheckboxChange(view, role.role)}
                            className="h-4 w-4 text-blueberry border-gray-300 rounded focus:ring-0"
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center font-normal">No items match the search input</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PermissionsEditorForAView = ({ users, currentRoles, isFiltered }: PermissionsEditorForAViewProps) => {
  const { permissionsMap } = usePermissionsState();
  const dispatch = usePermissionsDispatch();

  useEffect(() => {
    //Showing one user per row, with the roles the user has on the current view.
    const initialMap: Record<number, Record<Role, boolean>> = users.reduce((acc, user) => {
      acc[user.id] = initializeRolesForUser(user.id, currentRoles);
      return acc;
    }, {} as Record<number, Record<Role, boolean>>);
    dispatch({ type: PermissionsActionTypes.SetPermissions, payload: { permissionsMap: initialMap } });
  }, []);

  const handleCheckboxChange = (user: Users, role: Role) => {
    dispatch({ type: PermissionsActionTypes.ToggleRole, payload: { id: user.id, role } });
  };

  const handleSelectAllChange = (role: Role, toggled: boolean) => {
    dispatch({ type: PermissionsActionTypes.ToggleAllForRole, payload: { role, toggled } });
  };

  return (
    <div className="w-full text-blueberry">
      <div className="flow-root">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="h-72 overflow-y-auto">
            {users.length > 0 ? (
              <table className="min-w-full table-fixed border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky w-1/3 top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 pr-3 text-left font-light text-xs italic text-gray-500 backdrop-blur backdrop-filter"
                    >
                      View
                    </th>
                    {availableRoles.map((role) => (
                      <th
                        key={role.role}
                        scope="col"
                        className="w-1/6 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 text-left font-light text-xs italic text-gray-500 backdrop-blur backdrop-filter"
                      >
                        <Tippy content={role.description}>
                          <div className="flex flex-row select-none cursor-default items-center justify-center gap-x-1 w-fit">
                            {capitalizeFirstLetter(role.role)}
                            <QuestionMarkCircleIcon className="h-3.5 w-3.5 text-gray-400" />
                          </div>
                        </Tippy>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!isFiltered ? (
                    <tr className="bg-silver">
                      <td className="whitespace-nowrap pr-3 py-3 text-sm font-light italic text-gray-500 pl-2 border-b border-gray-300">Select all</td>
                      {availableRoles.map((role) => (
                        <td key={role.role} className="whitespace-nowrap px-3 py-3 text-xs text-gray-500 border-b border-gray-300">
                          <input
                            type="checkbox"
                            onChange={(e) => handleSelectAllChange(role.role, e.target.checked)}
                            checked={users.every((view) => permissionsMap?.[view.id]?.[role.role])}
                            className="h-4 w-4 text-blueberry border-gray-300 rounded focus:ring-0"
                          />
                        </td>
                      ))}
                    </tr>
                  ) : null}
                  {users.map((user, itemIdx) => (
                    <tr key={user.id}>
                      <td
                        title={user.firstName + ' ' + user.lastName}
                        className={classNames(
                          itemIdx !== users.length - 1 ? 'border-b border-gray-300' : '',
                          'whitespace-nowrap pr-3 text-sm font-semibold pl-2'
                        )}
                      >
                        {truncateAndEllipsis(user.firstName + ' ' + user.lastName, 30)}
                      </td>
                      {availableRoles.map((role) => (
                        <td
                          key={role.role}
                          className={classNames(
                            itemIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap px-3 py-3 text-xs text-gray-500'
                          )}
                        >
                          <input
                            type="checkbox"
                            checked={permissionsMap?.[user.id]?.[role.role]}
                            onChange={() => handleCheckboxChange(user, role.role)}
                            className="h-4 w-4 text-blueberry border-gray-300 rounded focus:ring-0"
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center font-normal">No items match the search input</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
