export const ToolbarLoadingSkeleton = () => {
  return (
    <div className="flex flex-row w-full gap-x-3 justify-end">
      <div
        className="space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-10 w-full my-1"
      ></div>
    </div>
  );
};
