import { capitalize, groupBy } from 'lodash';
import { ConversationFragment, Conversation_Part, EntryFragment, Submitter_Type } from '../../generated/graphql';
import moment from 'moment';
import { Fragment } from 'react';
import he from 'he';
const Bubble = ({
  text,
  color,
  direction,
  dateString,
  condensed,
}: {
  text: string;
  color: string;
  direction: 'right' | 'left';
  dateString?: string;
  condensed?: boolean;
}) => {
  return (
    <div className={`flex ${direction === 'right' ? 'justify-end' : 'justify-start'} gap-x-2 w-full`}>
      {!condensed && direction == 'right' && dateString && <div className="text-sm text-gray-400 mb-4 ml-2 self-end">{dateString}</div>}

      <div className={`flex ${condensed ? 'max-w-[100%]' : 'max-w-[65%]'}`}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={`${color} shadow-md cursor-default text-gray-800 py-3 px-4 rounded-lg whitespace-pre-line overflow-auto break-words text-left mb-3`}
        >
          {he.decode(text)}
        </div>
      </div>
      {!condensed && direction == 'left' && dateString && <div className="text-sm text-gray-400 mb-4 mx-2 self-end">{dateString}</div>}
    </div>
  );
};
const GroupedBubbles = ({ parts, condensed }: { parts: ConversationFragment[]; condensed?: boolean }) => {
  const isCustomer = parts[0].submitterType === Submitter_Type.Customer;
  return (
    <div className={`flex flex-col ${!condensed ? 'px-10 ' : ''}`}>
      {parts.map((part, i) => (
        <Bubble
          key={i}
          text={part.fullText ?? ''}
          color={isCustomer ? 'bg-milk' : 'bg-gray-300'}
          direction={isCustomer ? 'left' : 'right'}
          dateString={i === 0 ? moment(part.date).format('MMMM D, h:mma') : undefined}
          condensed={condensed}
        />
      ))}
    </div>
  );
};

export const FullConversation = ({
  includeTitle,
  entry,
  title,
  height,
  condensed,
}: {
  includeTitle?: boolean;
  entry?: EntryFragment;
  title?: string;
  height?: string;
  condensed?: boolean;
}) => {
  const initialPart: Conversation_Part = {
    submitterType: entry?.submitterType ? (entry?.submitterType as Submitter_Type) : Submitter_Type.Customer,
    fullText: entry?.text,
    date: entry?.date,
    submitter: entry?.submitter,
    userId: entry?.feedbackEntryText.conversationParts[0].userId,
  };
  let grouped: ConversationFragment[][] = [];
  if (allDatesTheSame(entry?.feedbackEntryText.conversationParts ?? [])) {
    grouped = entry?.feedbackEntryText.conversationParts.map((part) => [{ ...part }]) ?? [];
    condensed = true;
  } else {
    grouped = Object.values(
      groupBy(entry?.feedbackEntryText.conversationParts, (parts) => parts.submitterType + moment(parts.date).format('MMMM D, h:mm:ssa'))
    );
  }
  //Add initial part to the beginning of the array
  grouped.unshift([initialPart]);
  const firstCustomerIndex = grouped.findIndex((g) => g[0].submitterType === Submitter_Type.Customer);
  const firstAgentIndex = grouped.findIndex((g) => g[0].submitterType === Submitter_Type.Agent);

  return (
    <div className="flex flex-col " data-testid="full-conversation">
      {includeTitle ? <p className="text-lg pl-2 text-left">{title}</p> : null}
      <div
        className={`flex flex-col p-4  ${!includeTitle ? 'bg-gray-200' : ''} w-full ${
          height ? height : 'h-52'
        } overflow-y-auto gap-x-2 transition-all duration-150 `}
      >
        {!entry?.feedbackEntryText.conversationParts || entry.feedbackEntryText.conversationParts.length === 0 ? (
          <p>Error: 'No conversation was found for this entry.'</p>
        ) : (
          grouped.map((group, index) => {
            return (
              <Fragment key={index}>
                {index === firstAgentIndex || index === firstCustomerIndex ? (
                  <div
                    className={`${group[0]?.submitterType === Submitter_Type.Customer ? 'text-left' : 'text-right'} text-gray-800 pb-1 ${
                      !condensed ? 'px-10' : ''
                    }`}
                  >
                    {index === firstAgentIndex && initialPart.userId ? group[0].submitter : capitalize(group[0]?.submitterType.toString())}
                  </div>
                ) : null}
                <GroupedBubbles key={index} parts={group} condensed={condensed} />
              </Fragment>
            );
          })
        )}
      </div>
    </div>
  );
};

export const allDatesTheSame = (conversationParts: ConversationFragment[]) => {
  const grouped = groupBy(conversationParts, (cp) => cp.date);
  return Object.values(grouped).length === 1;
};
