import { useEffect, useState, Fragment } from 'react';
import { Chart_Bin_Type, FilterInput, GetCustomChartQuery, TeamCharts, useDataForFiltersLazyQuery } from '../../generated/graphql';
import { ArrayElement } from '../../utilities';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { CustomChart } from './CustomChart';
import { FilterCategory, IBadgeFilter, IFilter } from '../sections/Filters/FiltersTypes';
import { useNavigate } from 'react-router-dom';
import { EllipsisVerticalIcon, LinkIcon, PencilIcon } from '@heroicons/react/24/solid';
import { ChartBarSquareIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import Badge from './Badge';
import Tippy from '@tippyjs/react';
import SettingsMenu, { ISettingsItem } from '../baseComponents/SettingsMenu';
import toast from 'react-hot-toast';
import { buildFilterInputFromSavedFilterInput } from '../pages/ChartsPage';
import moment from 'moment';
import { useCustomChartCardHook } from '../hooks/CustomChartCardHook';
import { CustomChartCardSkeleton } from './CustomChartCardSkeleton';
import { classNames, truncateAndEllipsis } from '../../v2/util';
import { Popover, Transition } from '@headlessui/react';
import { AppRoutes } from '../../Routes';
import { getBadgeText, setUiFilters } from '../sections/Filters/FiltersUtil';
import { Icon } from '@iconify/react';

interface LoadableCustomChartCardProps {
  chartId: number;
  filterInput: FilterInput;
  deleteChart?: () => void;
  binType?: Chart_Bin_Type;
  duplicateWidget?: () => void;
  boardId?: number;
}
interface SharedInnerCustomChartCardProps {
  filterInput: FilterInput;
  deleteChart?: () => void;
  duplicateWidget?: () => void;

  boardId?: number;
}

interface FullCustomChartCardProps extends SharedInnerCustomChartCardProps {
  chartData: GetCustomChartQuery['getChart'];
  loading?: boolean;
  newCard?: boolean;
  hideHeader?: boolean;
  customHeight?: string;
}

export const LoadableCustomChartCard = ({ chartId, filterInput, deleteChart, duplicateWidget, binType, boardId }: LoadableCustomChartCardProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const customChartCardHook = useCustomChartCardHook({ teamId, orgId, chartId: chartId, filterInput, binType: binType });
  const chartData = customChartCardHook?.chartData;

  if (chartData)
    return (
      <FullCustomChartCard
        chartData={chartData}
        filterInput={filterInput}
        deleteChart={deleteChart}
        key={chartData.id}
        duplicateWidget={duplicateWidget}
        boardId={boardId}
      />
    );
  return <CustomChartCardSkeleton />;
};

const BaseChartCard = ({ children }: { children: React.ReactNode }) => {
  return <div className="custom-chart-card flex flex-col border-2 border-gray-200 rounded-lg w-full h-full">{children}</div>;
};

const BaseChartCardBody = ({ children, customHeight }: { children: React.ReactNode; customHeight?: string }) => {
  return (
    <div className="h-full bg-white rounded-b-lg">
      <div className={classNames('w-full', 'h-full')} id="chart-container">
        {children}
      </div>
    </div>
  );
};

export const FullCustomChartCard = ({
  chartData,
  filterInput,
  deleteChart,
  duplicateWidget,
  loading,
  newCard,
  hideHeader,
  customHeight,
  boardId,
}: FullCustomChartCardProps) => {
  return (
    <BaseChartCard>
      {!hideHeader ? (
        <CustomChartCardHeader
          title={chartData?.title}
          id={chartData?.id}
          newCard={newCard ?? false}
          deleteChart={deleteChart}
          filterInput={filterInput}
          loading={loading}
          chart={chartData}
          duplicateWidget={duplicateWidget}
          boardId={boardId}
        />
      ) : null}
      <BaseChartCardBody customHeight={customHeight}>
        <CustomChart customChartData={chartData} />
      </BaseChartCardBody>
    </BaseChartCard>
  );
};

interface CustomChartCardHeaderProps {
  chart?: GetCustomChartQuery['getChart'] | null;
  id: number;
  title: string;
  newCard: boolean;
  loading?: boolean;
  filterInput: FilterInput;
  deleteChart?: () => void;
  duplicateWidget?: () => void;

  boardId?: number;
}

const CustomChartCardHeader = ({ chart, id, title, newCard, loading, deleteChart, duplicateWidget, filterInput, boardId }: CustomChartCardHeaderProps) => {
  const { curTeamId: teamId, curOrgId: orgId, currentTeam } = useValidTeamAppContext();
  const [headerFilters, setHeaderFilters] = useState<IFilter[]>([]);
  const [getDataForFilters] = useDataForFiltersLazyQuery({ variables: { teamId, orgId } });

  let navigate = useNavigate();

  const navigateToEdit = () => {
    navigate(generateChartNavigationURL({ teamId, orgId, chartId: id, boardId }));
  };

  const settings: ISettingsItem[] = [
    {
      name: 'Edit Chart',
      id: 0,
      group: 'action',
      htmlId: 'edit-chart-widget',
      onClick: () => navigateToEdit(),
      icon: <PencilIcon className="w-5 h-5" />,
    },
    {
      name: 'Copy Chart URL',
      id: 1,
      group: 'action',
      htmlId: 'copy-chart-widget-url',
      onClick: () => copyChartLink({ chartId: id, teamId, orgId, boardId }),
      icon: <LinkIcon className="w-5 h-5" />,
    },
    {
      name: 'Duplicate Chart',
      id: 2,
      group: 'action-2',
      htmlId: 'duplicate-chart-widget',
      icon: <DocumentDuplicateIcon className="h-5 w-5" />,

      onClick: () => duplicateWidget?.(),
    },
    {
      name: 'Delete Chart',
      textColor: 'failure',
      id: 3,
      group: 'action-2',
      htmlId: 'delete-chart-widget',
      icon: <TrashIcon className="h-5 w-5" />,
      onClick: () => {
        deleteChart?.();
      },
    },
  ];

  useEffect(() => {
    if (chart?.seriesData) {
      Promise.all(
        chart.seriesData?.map(async (series) => {
          const convertedFilter = buildFilterInputFromSavedFilterInput(series.filterInput ?? {});
          await setUiFilters(
            convertedFilter,
            (filter) => {
              let withTeamName = filter.map((f) => {
                return { ...f, teamName: series.filterInput?.teamName };
              });

              withTeamName = withTeamName.sort((a, b) => {
                const textA = getBadgeText(a, new Set(withTeamName?.map((fltr) => fltr.teamName)).size < 2 && a.teamName === currentTeam.name);
                const textB = getBadgeText(b, new Set(withTeamName?.map((fltr) => fltr.teamName)).size < 2 && b.teamName === currentTeam.name);
                return textA.length - textB.length;
              });
              setHeaderFilters(withTeamName);
            },
            () => getDataForFilters({ variables: { teamId: series.team.id, orgId } })
          );
        })
      ).then(() => {});
    }
  }, []);

  return (
    <div className={`bg-silver justify-center text-white flex flex-col rounded-t-md px-4 py-2 cursor-pointer`} onClick={navigateToEdit}>
      <div className="flex flex-row justify-between items-center gap-x-2">
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row gap-x-2 items-center">
            <ChartBarSquareIcon className="h-5 w-5 xl:h-6 xl:w-6 text-gray-400" />
            <div className="flex flex-col justify-start">
              <h1 className="font-semibold text-sm 2xl:text-lg text-blueberry line-clamp-1">{title}</h1>
            </div>
          </div>
        </div>
        <div>
          {!newCard ? (
            <div className="flex flex-row gap-x-3 2xl:gap-x-4 items-center">
              <div className="min-w-fit">{headerFilters.length > 0 ? <FiltersPopover remainingFilters={headerFilters} /> : null}</div>
              <div className="flex flex-row items-center gap-x-3">
                {/* <Tippy theme="dark" delay={200} content={'Drag to rearrange widget'}> */}
                <div className="widget-drag-handle cursor-pointer hover:scale-110 duration-100">
                  <Icon icon="la:arrows-alt" color={'#292E5B'} className="w-4 h-4 2xl:h-5 2xl:w-5" onClick={(e) => e.stopPropagation()} />
                </div>
                {/*  </Tippy> */}
                <div
                  id="chart-card-settings-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SettingsMenu settings={settings} center>
                    <Tippy theme="dark" delay={200} content={'Settings'}>
                      <EllipsisVerticalIcon className="w-5 h-5 2xl:h-6 2xl:w-6 text-blueberry -mr-1 focus:outline-none hover:scale-110 duration-100" />
                    </Tippy>
                  </SettingsMenu>
                </div>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="flex flex-row gap-x-2 items-center text-white">
              <AdjustableLoadingIcon color={'white'} width={4} height={4} />
              <p className="text-md">Updating chart...</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const copyChartLink = ({ chartId, teamId, orgId, boardId }: { chartId: number; boardId?: number; teamId: number; orgId: number }) => {
  const chartNavigation = generateChartNavigationURL({ teamId, orgId, chartId, boardId });
  const chartUrl = window.location.protocol + '//' + window.location.host + chartNavigation;
  navigator.clipboard.writeText(chartUrl);
  toast.success('Link successfully copied!');
  return chartUrl;
};

export const getChartLink = ({ chartId, filterInput, teamId, orgId }: { chartId: number; filterInput: FilterInput; teamId: number; orgId: number }) => {
  const filter: FilterInput = {
    startDate: filterInput.startDate,
    ...(!moment().endOf('day').isSame(filterInput.endDate) ? { endDate: filterInput.endDate } : {}),
  };
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set('chart', encodeURIComponent(JSON.stringify(filter)));
  const newUrl = window.location.protocol + '//' + window.location.host + AppRoutes.v3FullPath.charts + `/${chartId}/edit?` + urlParams.toString();
  return newUrl;
};

const generateChartNavigationURL = ({ teamId, orgId, chartId, boardId }: { teamId: number; orgId: number; chartId: number; boardId?: number }) => {
  const urlParams = new URLSearchParams();
  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  if (boardId) urlParams.set('boardId', boardId.toString());
  return `${AppRoutes.v3FullPath.charts}/${chartId}/edit` + '?' + urlParams.toString();
};

const FilterBadge = ({
  filters,
  index,
  badgeRef,
  trimGroupTitle,
}: {
  filters: IFilter[];
  index: number;
  badgeRef?: React.RefObject<HTMLElement>;
  trimGroupTitle?: boolean;
}) => {
  const filter = filters[index];
  const { currentTeam } = useValidTeamAppContext();
  let badgeValue: IBadgeFilter = {
    text: getBadgeText(filter, new Set(filters?.map((fltr) => fltr.teamName)).size < 2 && filter.teamName === currentTeam.name),
    id: index.toString(),
    filter: filter,
  };
  const fullText = badgeValue.text;
  if (trimGroupTitle && badgeValue.filter.filterCategory === FilterCategory.GroupTitle) badgeValue.text = truncateAndEllipsis(badgeValue.text, 40);
  return (
    <Tippy disabled={fullText === badgeValue.text} content={trimGroupTitle ? fullText : ''} theme="dark">
      <div id="applied-filter" className="" key={index}>
        <Badge
          badge={badgeValue}
          ref={badgeRef}
          key={index}
          smaller
          color="bg-gray-300"
          textColor="text-blueberry"
          textFont="font-semibold"
          capitalize={false}
        />
      </div>
    </Tippy>
  );
};

const FiltersPopover = ({ remainingFilters }: { remainingFilters: IFilter[] }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <Popover className="relative">
      <Popover.Group>
        <Popover.Button className="flex" onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
          <div className="flex flex-row gap-x-2 items-center bg-gray-200 rounded-full px-3 text-blueberry">
            {/* <AdjustmentsHorizontalIcon className="h-4 w-4" /> */}
            <div className="flex flex-row text-xs 2xl:text-sm font-semibold  divide-x divide-opacity-30 divide-blueberry">
              <p className=" pr-2">Filters</p>
              <p className="pl-2">{remainingFilters.length}</p>
            </div>
          </div>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isShowing}
        >
          <Popover.Panel className={`absolute z-10 w-96 right-0 pt-1`} onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
            <div className="overflow-auto max-h-32 bg-white border-2 border-gray-300 shadow-md rounded-lg p-3 flex flex-row gap-y-1 overflow-y-auto gap-x-1 flex-wrap">
              {remainingFilters!.map((_, index) => (
                <FilterBadge filters={remainingFilters} index={index} key={index} />
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover.Group>
    </Popover>
  );
};
