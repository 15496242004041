import { useEffect, useState, useRef } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import { Widget } from '../../../generated/graphql';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

export const BOARD_WIDGET_HEIGHT = 2;
const AMOUNT_OF_COLUMNS = 3;

/**
 * Generates the react-grid-layout layout from widgets. For widgets that are not positioned, they are placed in the next available position.
 * @param widgets
 * @returns
 */
export const generateBoardLayout = (widgets: Widget[]): Layout[] => {
  const positionedWidgets = widgets.filter((widget) => widget.x >= 0 && widget.y >= 0).sort((a, b) => (a.y === b.y ? a.x - b.x : a.y - b.y));
  const unpositionedWidgets = widgets.filter((widget) => widget.x === -1 || widget.y === -1);

  // Find the first available space for each unpositioned widget
  unpositionedWidgets.forEach((widget) => {
    outerLoop: for (let y = 0; ; y += BOARD_WIDGET_HEIGHT) {
      for (let x = 0; x <= AMOUNT_OF_COLUMNS - widget.width; x++) {
        const spaceIsAvailable = positionedWidgets.every((existingWidget) => {
          return !(
            y < existingWidget.y + BOARD_WIDGET_HEIGHT &&
            y + BOARD_WIDGET_HEIGHT > existingWidget.y &&
            x < existingWidget.x + existingWidget.width &&
            x + widget.width > existingWidget.x
          );
        });

        if (spaceIsAvailable) {
          widget.x = x;
          widget.y = y;
          positionedWidgets.push(widget); // Add to positioned widgets
          break outerLoop; // Move to the next unpositioned widget
        }
      }
    }
  });

  // Convert all widgets (now positioned) to the Layout format
  return positionedWidgets.map((widget) => ({
    i: widget.id.toString(),
    minW: 1,
    x: widget.x,
    y: widget.y,
    w: widget.width,
    maxW: 3,
    minH: BOARD_WIDGET_HEIGHT,
    maxH: BOARD_WIDGET_HEIGHT,
    h: BOARD_WIDGET_HEIGHT,
  }));
};

export const BoardLayout = ({
  widgets,
  onLayoutChange,
  renderWidget,
}: {
  widgets: Widget[];
  onLayoutChange: (layout: Layout[]) => void;
  renderWidget: (widget: Widget) => JSX.Element;
}) => {
  const [containerSize, setContainerSize] = useState({ width: 1200, height: 800 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<Layout[]>(generateBoardLayout(widgets));

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetWidth / 3,
        });
      }
    };

    handleResize(); // Initial size calculation
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const newLayout = generateBoardLayout(widgets);
    setLayout(newLayout);
    // onLayoutChange(newLayout);
  }, [widgets]);

  function handleLayoutChange(newLayout: Layout[]) {
    onLayoutChange(newLayout);
    /* setLayout(newLayout); */
  }

  return (
    <div className="w-full h-full" ref={containerRef}>
      <GridLayout
        className="layout"
        layout={layout}
        width={containerSize.width}
        cols={AMOUNT_OF_COLUMNS}
        margin={[10, 10]}
        resizeHandles={['e', 'w']} // Only allow resizing horizontally
        onLayoutChange={handleLayoutChange}
        draggableHandle=".widget-drag-handle"
      >
        {layout.map((gridItem) => (
          <div key={gridItem.i} data-grid={gridItem}>
            {renderWidget(widgets.find((widget) => widget.id.toString() === gridItem.i)!)}
          </div>
        ))}
      </GridLayout>
    </div>
  );
};
