import { AssignedRoles, Domain, Role, Role_Attachments, UserRolesInput } from '../../../generated/graphql';

type PermissionsMapType = Record<number, Record<Role, boolean>>;

export interface PermissionsState {
  //Mapping ids (user id or team id or etc) to roles (eg. 1: {admin: true, member: false, viewer: true})
  permissionsMap: PermissionsMapType;
}

export const PermissionsInitialState: PermissionsState = {
  permissionsMap: {},
};

export enum PermissionsActionTypes {
  SetPermissions,
  ToggleRole,
  ToggleAllForRole,
}

type SetPermissionsPayload = { permissionsMap: PermissionsMapType };
type ToggleRolePayload = { id: number; role: Role };
type ToggleAllForRolePayload = { role: Role; toggled: boolean };

export type PermissionsAction =
  | { type: PermissionsActionTypes.SetPermissions; payload: SetPermissionsPayload }
  | { type: PermissionsActionTypes.ToggleRole; payload: ToggleRolePayload }
  | { type: PermissionsActionTypes.ToggleAllForRole; payload: ToggleAllForRolePayload };

export const PermissionsReducer = (state: PermissionsState, action: PermissionsAction): PermissionsState => {
  switch (action.type) {
    case PermissionsActionTypes.SetPermissions:
      return { ...state, permissionsMap: action.payload.permissionsMap };
    case PermissionsActionTypes.ToggleRole:
      return {
        ...state,
        permissionsMap: {
          ...state.permissionsMap,
          [action.payload.id]: {
            ...state.permissionsMap[action.payload.id],
            [action.payload.role]: !state.permissionsMap[action.payload.id][action.payload.role],
          },
        },
      };
    case PermissionsActionTypes.ToggleAllForRole:
      //If toggled, for all entries of the map, turn on the boolean for the given role
      //If not toggled, turn off the boolean for the given role
      const keys = Object.keys(state.permissionsMap);
      const toggled = action.payload.toggled;
      const curMap = state.permissionsMap;
      //solution without reduce:
      keys.forEach((key) => {
        curMap[Number(key)][action.payload.role] = toggled;
      });
      return {
        ...state,
        permissionsMap: curMap,
      };

    default:
      throw new Error('Unhandled action type');
  }
};

export const initializeRolesForView = (viewId: number, currentRoles?: Role_Attachments[]): Record<Role, boolean> => {
  const rolesState: Record<Role, boolean> = {
    [Role.Admin]: false,
    [Role.Contributor]: false,
    [Role.Member]: false,
    [Role.Replier]: false,
    [Role.Viewer]: false,
    [Role.SuperAdmin]: false,
  };

  if (!currentRoles) {
    //default
    rolesState[Role.Viewer] = true;
    return rolesState;
  }

  currentRoles.forEach((roleAttachment) => {
    if (roleAttachment.domain === Domain.Team && roleAttachment.domainId === viewId) {
      rolesState[roleAttachment.role as Role] = true;
    }
  });

  return rolesState;
};

export const initializeRolesForUser = (userId: number, currentRoles?: Role_Attachments[]): Record<Role, boolean> => {
  const rolesState: Record<Role, boolean> = {
    [Role.Admin]: false,
    [Role.Contributor]: false,
    [Role.Member]: false,
    [Role.Replier]: false,
    [Role.Viewer]: false,
    [Role.SuperAdmin]: false,
  };

  if (!currentRoles) {
    //default
    rolesState[Role.Viewer] = true;
    return rolesState;
  }

  currentRoles.forEach((roleAttachment) => {
    if (roleAttachment.domain === Domain.Team && roleAttachment.userId === userId) {
      rolesState[roleAttachment.role as Role] = true;
    }
  });

  return rolesState;
};

//From map to a list of view roles for a given user
export const permissionMapToAssignedRolesForAUser = (permissionsMap: PermissionsMapType): AssignedRoles[] => {
  const assignedRoles: AssignedRoles[] = [];
  Object.entries(permissionsMap).forEach(([viewId, roles]) => {
    Object.entries(roles).forEach(([role, hasPermission]) => {
      if (hasPermission) {
        assignedRoles.push({
          domain: Domain.Team,
          domainId: Number(viewId),
          role: role as Role,
        });
      }
    });
  });
  return assignedRoles;
};

//From map to a list of user roles for a given view
export const permissionMapToAssignedRolesForAView = (permissionsMap: PermissionsMapType): UserRolesInput[] => {
  const assignedRoles: UserRolesInput[] = [];
  Object.entries(permissionsMap).forEach(([userId, roles]) => {
    const userRoles: Role[] = [];
    Object.entries(roles).forEach(([role, hasPermission]) => {
      if (hasPermission) {
        userRoles.push(role as Role);
      }
    });
    assignedRoles.push({
      roles: userRoles,
      userId: Number(userId),
    });
  });
  return assignedRoles;
};
