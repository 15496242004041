import React, { useEffect, useState, useContext } from 'react';
import _, { cloneDeep } from 'lodash';
import { logEvent, Events } from '../AnalyticsUtil';
import { toast } from 'react-hot-toast';
import {
  EntryFragment,
  SentenceEntryFragment,
  IChartSeries,
  IChartableItem,
  GetChildrenToAssignQuery,
  GetChildrenToAssignQueryVariables,
  useGetChildrenToAssignLazyQuery,
  GroupDataFragment,
  useGetGroupLazyQuery,
  useEditGroupMutation,
  useGetPreviewPageGroupsLazyQuery,
  Group_Status,
  Group_Type,
  useFeedbackSentencesLazyQuery,
  Insights_Time_Window,
  useRemoveTagFromGroupMutation,
  useCreateTagMutation,
  useTagGroupMutation,
  GetTagsDocument,
  useTogglePinGroupMutation,
  EditGroupMutationFn,
  DeleteChildMutationFn,
  TogglePinGroupMutationFn,
  GetGroupQueryResult,
  TagGroupMutationFn,
  RemoveTagFromGroupMutationFn,
  useLogGroupViewMutation,
  CreateTagMutationFn,
  FeedbackSentencesQueryVariables,
  FeedbackSentencesQuery,
  GetGroupQueryVariables,
  GetGroupSentencesQueryVariables,
  GetGroupQuery,
  GetGroupSentencesQuery,
  useUpdateGroupOwnerMutation,
  useRemoveGroupOwnerMutation,
  UpdateGroupOwnerMutationFn,
  RemoveGroupOwnerMutationFn,
  useGetTagsLazyQuery,
  DataForFiltersDocument,
  GroupDependencies,
  useGetGroupSentencesLazyQuery,
  useGetChildCandidatesLazyQuery,
  GetChildCandidatesQuery,
  GetChildCandidatesQueryVariables,
  useAssignChildMutation,
  useAssignChildrenMutation,
  useGetChildrenLazyQuery,
  GetChildrenQueryVariables,
  GetChildrenQuery,
  AssignChildMutationFn,
  useGetOrphansLazyQuery,
  AssignChildrenMutationFn,
  useDeleteChildMutation,
  GroupTaxonomyFragment,
  useRemoveChildMutation,
  RemoveChildMutationFn,
  Group_Trending,
  useGetGroupsLazyQuery,
  GroupInsightFragment,
  useTeamGroupsListLazyQuery,
  Submitter_Type,
  GroupSentenceDataFragment,
  useGetChildrenLightLazyQuery,
  GetChildrenLightQueryVariables,
  GetChildrenLightQuery,
  useGetGroupsLightTaxonomyQuery,
  useGetTaxonomyGroupLazyQuery,
  GetTaxonomyGroupQuery,
  GetTaxonomyGroupQueryVariables,
  Group,
  GroupMembershipFragment,
} from '../../generated/graphql';
import { FilterInput } from '../../generated/graphql';
import AppContext from '../contexts/AppContext';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { LazyQueryExecFunction } from '@apollo/client';
import moment from 'moment';
import { getTotalGroupLoadEvent } from '../../latencyTracker';
import { getTaxonomyMap } from '../../v3/lib/taxonomy';
import { saveGroupIdsToLocalStorage } from '../../v3/lib/taxonomy';
import { TaxonomyDispatchContext } from '../../v3/context/TaxonomyDispatchContext';
import { TaxonomyContext } from '../../v3/context/TaxonomyContext';
import { AppRoutes } from '../../Routes';

export type Sentence = {
  id: string;
  is_distillate: boolean;
  is_title?: number | null | undefined;
  text?: string | null | undefined;
  preceedingText?: string | null | undefined;
  proceedingText?: string | null | undefined;
  groupId?: string | null;
};

export type Creator = {
  isUnwrapGenerated: boolean;
  creatorEmail?: string; // because for some reason this is nullable on the db
};

export type SentenceEntry = Sentence & {
  entry: EntryFragment;
};

export interface GroupBaseProps {
  id: string;
  title?: string | null | undefined;
  totalEntries: number;
  creator: Creator;
  denominator: number;
  processing?: boolean;
  progress?: number;
  pinnedByUser: boolean;
  centroid: string;
  status: Group_Status;
  isExactMatch: boolean;
  type: Group_Type;
  sentences: SentenceEntry[];
  entries: EntryFragment[];
  tags?: ITag[] | undefined | null;
  isNew?: boolean | null | undefined;
  relativeShare?: number;
  relativeShareFull?: number;
  date: number;
  dateCreated?: number | null | undefined;
  centroidText?: string;
  uniqueEntries?: number;
  statistics?: any;
  totalDescendents: number;
  isPinnedByUser?: boolean;
  insight?: GroupInsightFragment | null;
}
export class GroupBase {
  id: string;
  title?: string | null | undefined;
  totalEntries: number;
  denominator: number;
  creator: Creator;
  pinnedByUser: boolean;
  centroid: string;
  status: Group_Status;
  isExactMatch: boolean;
  type: Group_Type;
  processing?: boolean;
  progress?: number;
  entries: EntryFragment[];
  tags?: ITag[] | undefined | null;
  /** Is this even used anymore it's referenced in code but I haven't seen this show up at all. */
  isNew?: boolean | null | undefined;
  /** This represents the percentage of filtered feedback, with the filter applied to the denominator */
  relativeShare?: number;
  /** This represent the percentage or unfiltered feedback, all feedback is in the denominator */
  relativeShareFull?: number;
  date: number;
  totalDescendents: number;
  isPinnedByUser?: boolean;
  insight?: GroupInsightFragment | null;
  constructor(props: GroupBaseProps) {
    this.id = props.id;
    this.title = props.title;
    this.totalEntries = props.uniqueEntries !== undefined ? props.uniqueEntries : props.totalEntries;
    this.denominator =
      props.statistics?.denominator?.denominatorUnfiltered !== undefined ? props.statistics?.denominator?.denominatorUnfiltered : props.denominator;
    this.pinnedByUser = props.isPinnedByUser !== undefined ? props.isPinnedByUser : props.pinnedByUser;
    this.processing = props.processing;
    this.progress = props.progress;
    this.creator = props.creator;
    this.centroid = props.centroidText || props.centroid;
    this.status = props.status;
    this.isExactMatch = props.isExactMatch;
    this.type = props.type;
    this.entries = props.entries;
    this.tags = props.tags;
    this.isNew = props.isNew;
    this.relativeShare = props.relativeShare;
    this.relativeShareFull = props.relativeShareFull;
    this.date = props.dateCreated ? props.dateCreated : props.date;
    this.totalDescendents = props.totalDescendents;
    this.insight = props.insight;
  }
}
export interface Ancestry {
  id: string;
  title: string;
}

export interface TaxonomyGroupProps extends GroupBaseProps {
  showChildren: boolean;
  parentId: string | null;
  children: TaxonomyGroup[] | null; // null means we haven't loaded the children yet
  trending: Group_Trending | null | undefined;
  canAddChildren: boolean | undefined | null;
  ancestors?: Ancestry[]; // used to render the actual breadcrumbs
}
export class TaxonomyGroup extends GroupBase {
  showChildren: boolean;
  parentId: string | null;
  totalDescendents: number;
  children: TaxonomyGroup[] | null; // null means we haven't loaded the children yet
  trending: Group_Trending | null | undefined;
  canAddChildren: boolean | undefined | null;
  ancestors?: Ancestry[]; // used to render the actual breadcrumbs

  // log the function that called this function from the stact trace

  constructor(props: TaxonomyGroupProps) {
    super(props);
    this.showChildren = props.showChildren;
    this.totalDescendents = props.totalDescendents;
    this.children = props.children;
    this.trending = props.trending;
    this.canAddChildren = props.canAddChildren;
    this.parentId = props.parentId;
    this.ancestors = props.ancestors ?? [];
  }
}

export interface GroupFullProps extends GroupBaseProps {
  ownerId?: number;
  centroid: string;
  title?: string | null;
  percentChange?: number | null;
  percentChangeTimeRange?: Insights_Time_Window | null;
  aggregateData?: number[];
  normalizedData?: number[];
  tooltipLabels?: string[];
  chartLabels?: string[];
  filterInput?: FilterInput;
  date: number;
  chartData?: any;
  chartOptions?: any;
  parentId?: number | null;
}

export class GroupFull extends GroupBase {
  ownerId?: number;
  isNew?: boolean;
  percentChange?: number | null;
  percentChangeTimeRange?: Insights_Time_Window | null;
  aggregateData?: number[];
  normalizedData?: number[];
  tooltipLabels?: string[];
  chartLabels?: string[];
  sentences: SentenceEntry[];
  entries: EntryFragment[];
  date: number;
  chartData?: any;
  chartOptions?: any;
  parentId?: number | null;
  filterInput?: FilterInput;
  constructor(props: GroupFullProps) {
    super(props);
    this.parentId = props.parentId;
    this.ownerId = props.ownerId;
    this.percentChange = props.percentChange;
    this.percentChangeTimeRange = props.percentChangeTimeRange;
    this.aggregateData = props.aggregateData;
    this.normalizedData = props.normalizedData;
    this.tooltipLabels = props.tooltipLabels;
    this.chartLabels = props.chartLabels;
    this.sentences = props.sentences;
    this.entries = props.entries;
    this.date = props.date;
    this.chartData = props.chartData;
    this.chartOptions = props.chartOptions;
    this.filterInput = props.filterInput;
  }
}

export interface ITag {
  id: number;
  name: string;
}
export enum GroupType {
  Cluster,
  Search,
}

export interface GroupHookProps {
  teamId: number;
  orgId: number;
  teamName: string;
  orgName: string;
  pageName: string;
  selectedDenominator?: IDropDownItem | undefined;
  filterInput?: FilterInput;
  sentenceFilterInput?: FilterInput;
  status?: Group_Status;
  pageSize?: number;
  sentenceIdToSearch?: number;
  teamUuid?: string;
  email?: string;
  sentencesTake?: number;
}

/**
 *  Rules for hook
 * All state definitions go at the top including all 'Mutation' and 'Query' types.
 *
 * All useEffects follow the state definitions.
 *
 * All function definitions go below
 * @returns
 */
export const useGroupHook = ({
  teamId,
  orgId,
  teamName,
  orgName,
  pageName,
  selectedDenominator,
  filterInput,
  status,
  pageSize,
  email,
  sentencesTake,
}: GroupHookProps) => {
  const { app } = useContext(AppContext);
  // groups
  const [groups, setGroups] = useState<(GroupBase | GroupFull | TaxonomyGroup)[]>([]);
  const taxonomy = useContext(TaxonomyContext);
  const dispatch = useContext(TaxonomyDispatchContext);
  const setTaxonomy = (taxonomy: Map<string, TaxonomyGroup>) => {
    dispatch({ type: 'setTaxonomy', payload: { taxonomy } });
  };
  const [currentGroup, setCurrentGroup] = useState<GroupBase | GroupFull>();
  const [togglePinGroupMutation] = useTogglePinGroupMutation();
  const [getGroupFull, groupQuery] = useGetGroupLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [editGroup, editResult] = useEditGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [getOrphans, orphansQuery] = useGetOrphansLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      belongs: true,
      take: pageSize,
      filterInput: filterInput ?? {},
      teamUuid: app?.currentUuid ?? undefined,
      status,
    },
  });

  const [getListQuery, listQuery] = useTeamGroupsListLazyQuery({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  const [getGroupsQuery, groupsQuery] = useGetGroupsLazyQuery({
    // setting this to allow caching - We may not be using the apollo source
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      belongs: true,
      sentencesTake: sentencesTake != null ? sentencesTake : 3,
      take: pageSize,
      filterInput: filterInput ?? {},
      teamUuid: app?.currentUuid ?? undefined,
      status,
    },
  });

  const [getChildrenToAssignQuery, childrenToAssignResult] = useGetChildrenToAssignLazyQuery({});
  const [getChildCandidatesQuery, childCandidatesQuery] = useGetChildCandidatesLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [getChildrenLightQuery, childrenLightQuery] = useGetChildrenLightLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [assignChildMutation, assignChildResult] = useAssignChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [deleteChildMutation, deleteChildResult] = useDeleteChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [assignChildrenMutation, assignChildrenResult] = useAssignChildrenMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [removeChildMutation, removeChildResult] = useRemoveChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [getTaxonomyGroupQuery] = useGetTaxonomyGroupLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

  const [childCandidates, setChildCandidates] = useState<TaxonomyGroup[]>([]);
  const [children, setChildren] = useState<GroupMembershipFragment[]>([]);
  const [childrenToAssign, setChildrenToAssign] = useState<TaxonomyGroup[]>([]);
  const [getGroupSentencesQuery] = useGetGroupSentencesLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [logGroupView] = useLogGroupViewMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [getPreviewSearches, previewSearchesQuery] = useGetPreviewPageGroupsLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      email: email ?? '',
      filterInput: filterInput ?? {},
      sentencesTake: sentencesTake != null ? sentencesTake : 3,
      take: 10,
      teamUuid: app?.currentUuid ?? '',
    },
  });
  const [updateGroupOwner] = useUpdateGroupOwnerMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [removeGroupOwner] = useRemoveGroupOwnerMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  // tags
  const [getAllTags, tags] = useGetTagsLazyQuery({
    variables: { teamId },
  });
  const [removeTag] = useRemoveTagFromGroupMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GetTagsDocument, variables: { teamId } }],
  });
  const [createTag] = useCreateTagMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GetTagsDocument, variables: { teamId } }],
  });
  const [tagGroup] = useTagGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  // sentences
  const [sentencesQuery, sentencesResult] = useFeedbackSentencesLazyQuery({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [similarSentences, setSimilarSentences] = useState<SentenceEntryFragment[]>();

  //The currentGroupFilter is the filter that comes from the Modal.
  //Because we have a date picker in the modal, we need to use that FilterInput instead of the page-level one.
  //This filter is cloned inside the GroupModal, then the dates are changed from the in-modal date picker.
  const [currentGroupFilter, setCurrentGroupFilter] = useState<FilterInput | undefined>(filterInput);
  //This state is to distinguish from reloading group just for entries vs reloading for all data
  const [reloadingCurrentGroup, setReloadingCurrentGroup] = useState<boolean>(false);

  useEffect(() => {
    if (!app?.isPreviewMode && teamId) {
      getAllTags();
    }
  }, []);

  useEffect(() => {
    setCurrentGroup(undefined);
  }, [filterInput]);

  useEffect(() => {
    if (!currentGroup) setCurrentGroupFilter(filterInput);
  }, [selectedDenominator, filterInput]);

  const loadListView = async (teamId: number, filterInput: FilterInput, teamUuid?: string) => {
    const usedVariables = groupsQuery.variables;
    if (groupsQuery.called && teamId === usedVariables?.teamId && filterInput === usedVariables?.filterInput && filterInput === usedVariables?.teamUuid) return;
    setGroups([]);
    getListQuery({
      variables: {
        teamId,
        belongs: true,
        sentencesTake: sentencesTake != null ? sentencesTake : 3,
        take: pageSize,
        skip: 0,
        filterInput: filterInput ?? {},
        teamUuid: app?.currentUuid ?? undefined,
        status,
      },
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const initialModalLoaded = performance.now();
    const updateData = async () => {
      const groupId = currentGroup?.id ?? groupQuery.variables?.groupId;
      //If there's no groupId, or if the team has changed (user changed to another team while viewing a Group), don't fetch the previously active group.
      if (!groupId || teamId !== groupQuery.variables?.teamId) return;
      setReloadingCurrentGroup(true);
      const sentencesQuery = getGroupSentencesQuery({
        variables: {
          groupId: groupId.toString(),
          teamId,
          belongs: true,
          sentencesTake: 20,
          sentencesSkip: 0,
          filterInput: currentGroupFilter ?? {},
          teamUuid: app?.currentUuid ?? undefined,
          includeDescendantsOnMappings: true,
        },
      });
      const dataGroupQuery = getGroupFull({
        variables: {
          groupId: groupId.toString(),
          teamId,
          belongs: true,
          sentencesTake: 0,
          sentencesSkip: 0,
          filterInput: currentGroupFilter ?? {},
          teamUuid: app?.currentUuid ?? undefined,
          includeDescendantsOnMappings: true,
        },
      });
      const [dataGroup, sentences] = await Promise.all([dataGroupQuery, sentencesQuery]);
      let dataGroupData = cloneDeep(dataGroup.data?.getGroup);
      let sentencesGroupData = cloneDeep(sentences.data?.getGroup);
      let merged;
      if (dataGroupData && sentencesGroupData) {
        merged = {
          ...dataGroupData,
          groupEntries: [...(dataGroupData?.groupEntries ?? []), ...(sentencesGroupData?.groupEntries ?? [])],
        };
      }
      if (!abortController.signal.aborted && merged) {
        setCurrentGroup(getGroups([merged])[0]);
        setReloadingCurrentGroup(false);
        const event = getTotalGroupLoadEvent({ view: pageName.includes('Home') ? 'home' : 'list', duration: initialModalLoaded });
        window.dispatchEvent(event);
      }
    };
    updateData();
    return () => {
      // this is a cleanup function that React will run when a component unmounts
      abortController.abort(); // aborting any remaining operations by calling .abort()
    };
  }, [currentGroupFilter]);
  useEffect(() => {
    if (!currentGroup) {
      setCurrentGroupFilter(filterInput);
    } else {
      logGroupView({
        variables: {
          groupId: currentGroup.id.toString(),
          teamId,
        },
      });
    }
  }, [currentGroup?.id]);

  return {
    groups,
    taxonomy,
    tags: (tags.data?.getTags as ITag[]) ?? [],
    currentGroup,
    similarSentences,
    childrenToAssign,
    childCandidates,
    children,
    app,
    previewSearchesQuery,
    groupsQuery,
    orphansQuery,
    groupQuery,
    listQuery,
    loadingStatuses: {
      fetchingGroups: listQuery.loading,
      fetchingMoreGroups: groupsQuery.loading,
      loadingAllSentences: groupQuery.loading,
      editResultLoading: editResult.loading,
      loadingSimilarSentences: sentencesResult.loading,
      loadingCurrentGroup: reloadingCurrentGroup,
      discardingGroup: editResult.loading,
      fetchingOrphans: orphansQuery.loading,
      fetchingChildren: childrenLightQuery.loading,
      fetchingChildCandidates: childCandidatesQuery.loading,
      assigningChild: assignChildResult.loading,
      assignChildren: assignChildrenResult.loading,
      gettingChildrenToAssign: childrenToAssignResult.loading,
    },

    /** Raw GQL Mutations */
    setCurrentGroup,
    setGroups,
    setTaxonomy,
    getGroupsQuery,
    getOrphans,
    getPreviewSearches,
    editGroup,
    togglePinGroupMutation,
    getChildrenToAssign,
    /** Group Hook specific Functionality */
    loadListView,
    replaceOrAddToSearchGroups: (group: GroupDataFragment) =>
      replaceOrAddToSearchGroups(
        group,
        groups,
        setGroups,
        (groupData) => addGroup(groupData, setGroups, taxonomy, setTaxonomy, getTaxonomyMap),
        taxonomy,
        setTaxonomy,
        //@ts-ignore
        getTaxonomyMap
      ),
    addGroup: (group: GroupDataFragment) => addGroup(group, setGroups, taxonomy, setTaxonomy, getTaxonomyMap),
    refetchSimilarSentences: (query: string, page: number, pageSize: number, endOfDataSetDb?: () => void, cb?: () => void, queryChanged?: boolean) =>
      refetchSimilarSentences(
        query,
        currentGroup,
        teamId,
        getActiveFilter(currentGroup?.id, currentGroup?.id, currentGroupFilter, filterInput),
        page,
        pageSize,
        sentencesQuery,
        similarSentences ?? [],
        setSimilarSentences,
        endOfDataSetDb,
        cb,
        queryChanged
      ),
    loadMoreSentences: (groupId: string, page: number, pageSize: number, endOfDataSetDb: () => void, cb: () => void) =>
      refetchGroupWithPaginatedSentences(
        groupId,
        teamId,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        page,
        pageSize,
        currentGroup,
        setCurrentGroup,
        getGroupSentencesQuery,
        app?.currentUuid,
        endOfDataSetDb,
        cb
      ),
    clearSimilarSentences: () => setSimilarSentences([]),
    clearCurrentGroupEntries: () => {
      if (currentGroup) setCurrentGroup({ ...currentGroup, entries: [] });
    },
    loadAllSentences: (groupId: string) =>
      refetchGroupWithAllSentences(
        groupId,
        teamId,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        groups,
        setGroups,
        getGroupFull,
        app?.currentUuid
      ),
    updateProgress: (groupId: string, newProgress: number) => updateProgress(groupId, newProgress, groups, setGroups, taxonomy, setTaxonomy),
    editTitle: (groupId: string, title: string) =>
      editTitle(
        groupId,
        teamId,
        title,
        groups,
        groupQuery,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        editGroup,
        setGroups,
        taxonomy,
        (taxonomy) => setTaxonomy(taxonomy)
      ),
    updateOwner: (groupId: string, userId: number, cb?: () => void) =>
      updateOwner(
        groupId,
        teamId,
        orgId,
        userId,
        groups,
        groupQuery,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        updateGroupOwner,
        setGroups,
        currentGroup as GroupFull,
        setCurrentGroup,
        cb
      ),
    removeOwner: (groupId: string, cb?: () => void) =>
      removeOwner(
        groupId,
        teamId,
        groups,
        groupQuery,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        removeGroupOwner,
        setGroups,
        currentGroup as GroupFull,
        setCurrentGroup,
        cb
      ),
    copyGroupLink: (groupId: string, filterInput?: FilterInput) =>
      copyGroupLink(groupId, getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput) ?? {}, teamId, orgId, 'group'),
    handleCreateTag: (groupId: string, name: string, cb?: () => void) =>
      handleCreateTag(
        groupId,
        teamId,
        orgId,
        name,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        createTag,
        groupQuery,
        currentGroup,
        setCurrentGroup,
        cb
      ),
    handleRemoveTag: (groupId: string, tagId: number, cb?: () => void) =>
      handleRemoveTag(groupId, tagId, teamId, removeTag, groupQuery, currentGroup, setCurrentGroup, cb),
    handleTagGroup: (groupId: string, tagId: number, cb?: () => void) =>
      handleTagGroup(
        groupId,
        tagId,
        teamId,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        tagGroup,
        groupQuery,
        currentGroup,
        setCurrentGroup,
        cb
      ),
    discardGroup: (groupId: string, cb?: () => void): Promise<GroupDependencies | void> =>
      discardGroup(groupId, teamId, groups, taxonomy, setTaxonomy, setGroups, editGroup, setCurrentGroup, cb),
    togglePinGroup: (groupId: string, cb?: () => void) =>
      togglePinGroup(groupId, teamId, groups, taxonomy, setTaxonomy, currentGroup, setCurrentGroup, setGroups, togglePinGroupMutation, cb),
    updateCurrentGroupFilter: (currentGroupFilter: FilterInput) => setCurrentGroupFilter(currentGroupFilter),
    getChildCandidates: async (teamId: number, filterInput: FilterInput, groupId: string, query: string, cb: () => void) =>
      await getChildCandidates(teamId, filterInput, groupId, query, children, getChildCandidatesQuery, (candidates) => setChildCandidates(candidates), cb),
    getChildren: async (teamId: number, filterInput: FilterInput, groupId: string, cb: () => void) =>
      await getChildrenLight(teamId, filterInput, groupId, getChildrenLightQuery, (children) => setChildren(children), cb),
    openParent: (teamId: number, filterInput: FilterInput, groupId: string, cb?: () => void) =>
      openParent(teamId, filterInput, groupId, taxonomy, (taxonomy) => setTaxonomy(taxonomy), getChildrenLightQuery, cb),
    assignChild: (teamId: number, filterInput: FilterInput, parentGroupId: string, childGroupId: string, type?: 'Parent' | 'Child', cb?: () => void) =>
      assignChild(
        teamId,
        filterInput,
        parentGroupId,
        childGroupId,
        taxonomy,
        childCandidates,
        (candidates) => setChildCandidates(candidates),
        children,
        (children) => setChildren(children),
        (taxonomy) => setTaxonomy(taxonomy),
        assignChildMutation,
        getTaxonomyGroupQuery,
        type,
        cb
      ),
    deleteChild: (teamId: number, filterInput: FilterInput, parentGroupId: string, childGroupId: string, cb?: () => void) =>
      deleteChild(
        teamId,
        filterInput,
        parentGroupId,
        childGroupId,
        taxonomy,
        children,
        (children) => setChildren(children),
        (taxonomy) => setTaxonomy(taxonomy),
        deleteChildMutation,
        cb
      ),
    assignChildren: (teamId: number, filterInput: FilterInput, parentGroupId: string, childGroupIds: string[], cb?: () => void) =>
      assignChildren(
        teamId,
        filterInput,
        parentGroupId,
        taxonomy,
        (taxonomy) => setTaxonomy(taxonomy),
        assignChildrenMutation,
        childGroupIds,
        children,
        (children) => setChildren(children),
        childrenToAssign.filter((child) => childGroupIds.includes(child.id)),
        (children) => setChildrenToAssign(children),
        cb
      ),
    removeChildFromParent: (teamId: number, filterInput: FilterInput, parentGroupId: string, childGroupId: string, cb?: () => void) =>
      removeChildFromParent(teamId, filterInput, parentGroupId, childGroupId, taxonomy, (taxonomy) => setTaxonomy(taxonomy), removeChildMutation, cb),
    getCurrentGroup: (teamId: number, filterInput: FilterInput, groupId: string, cb?: () => void, redirect?: () => void) =>
      getCurrentGroup(teamId, filterInput, groupId, getGroupFull, (group) => setCurrentGroup(group), 10, cb, redirect),
    getPotentialChildren: (teamId: number, filterInput: FilterInput, groupId: string, cb?: () => void) =>
      getChildrenToAssign(teamId, filterInput, groupId, taxonomy, (children) => setChildrenToAssign(children), getChildrenToAssignQuery, cb),
  };
};

//Even though all actions that require Filters are applied to the currentGroup, this function exists for future changes/reference.
//This basically returns which filter the functions exported by the hook should use (if the page-level one or the group modal one)
const getActiveFilter = (
  groupId: string | undefined,
  currentGroupId: string | undefined,
  currentGroupFilter: FilterInput | undefined,
  filterInput: FilterInput | undefined
) => {
  if (groupId === currentGroupId) return currentGroupFilter;
  return filterInput;
};

/**
 * Updates progress on a search group type
 * @param searchGroupId
 * @param newProgress
 */
const updateProgress = (
  searchGroupId: string,
  newProgress: number,
  groups: GroupBase[],
  setGroups: (groups: GroupBase[]) => void,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void
) => {
  const updatedGroups = _.cloneDeep(groups);
  const updatedGroup: GroupFull = updatedGroups.find((sg) => sg.id === searchGroupId) as GroupFull;
  if (updatedGroup) {
    updatedGroup.progress = newProgress;
  }
  setGroups(updatedGroups);
  if (taxonomy.get(searchGroupId)) {
    const updatedTaxonomy = new Map(taxonomy);
    updatedTaxonomy.set(searchGroupId, { ...updatedTaxonomy.get(searchGroupId)!, progress: newProgress });
    setTaxonomy(updatedTaxonomy);
  }
};

const refetchGroupWithAllSentences = async (
  groupId: string,
  teamId: number,
  filterInput: FilterInput | undefined,
  groups: GroupBase[],
  setGroups: (groups: GroupBase[]) => void,
  getGroupFull: LazyQueryExecFunction<GetGroupQuery, GetGroupQueryVariables>,
  teamUuid?: string,
  cb?: (data: GroupBase) => void
) => {
  await getGroupFull({
    variables: {
      teamId,
      groupId: groupId,
      teamUuid: teamUuid,
      filterInput: filterInput ?? {},
      sentencesTake: 2000,
    },
    onCompleted(data) {
      cb ? cb(getGroups([data.getGroup])[0]) : replaceSentenceMappings(groupId, data.getGroup.groupEntries, groups, setGroups);
    },
  });
};

const refetchGroupWithPaginatedSentences = async (
  groupId: string,
  teamId: number,
  filterInput: FilterInput | undefined,
  page: number,
  pageSize: number,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  getGroupSentencesQuery: LazyQueryExecFunction<GetGroupSentencesQuery, GetGroupSentencesQueryVariables>,
  teamUuid?: string,
  endOfDataSetCb?: () => void,
  cb?: () => void
) => {
  await getGroupSentencesQuery({
    variables: {
      teamId,
      groupId: groupId,
      teamUuid: teamUuid,
      filterInput: filterInput,
      sentencesTake: pageSize,
      sentencesSkip: page,
      includeDescendantsOnMappings: true,
    },
    onCompleted(data) {
      if (!currentGroup) {
        cb?.();
        return;
      }
      const updatedGroup = getGroupSentences(data);
      if (updatedGroup.sentences.length === 0 || updatedGroup.sentences.length === currentGroup.totalEntries) {
        endOfDataSetCb?.();
      }
      const newGroup = {
        ...currentGroup,
        entries: [
          ...currentGroup.entries,
          ...updatedGroup.entries.filter((updatedEntry) => !currentGroup.entries.find((currentEntry) => currentEntry.id === updatedEntry.id)),
        ],
      };
      setCurrentGroup(newGroup);
      cb?.();
    },
  });
};

export const replaceSentenceMappings = (
  searchGroupId: string,
  newSentencesMappings: GroupDataFragment['groupEntries'],
  groups: GroupBase[],
  setGroups: (groups: GroupBase[]) => void
) => {
  if (!newSentencesMappings) {
    return;
  }
  const updatedGroups = _.cloneDeep(groups);
  const updatedGroup = updatedGroups.find((sg) => sg.id === searchGroupId);
  if (!updatedGroup) return;
  // we need to sort the sentences by date because the query doesn't return them in order
  updatedGroup.entries = newSentencesMappings
    .map((sm) => sm.mappedSentences[0].entry)
    .sort((a, b) => (b?.date ?? 0) - (a?.date ?? 0))
    .filter((entry) => entry) as EntryFragment[];
  setGroups(updatedGroups);
};

/**
 * Adds a new cluster/group to the beginning of the group list.
 * @param group
 */
export const addGroup = (
  group: GroupDataFragment,
  setGroups: React.Dispatch<React.SetStateAction<GroupBase[]>>,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  getTaxonomy: (groups: GroupDataFragment[] | null | undefined) => Map<string, TaxonomyGroup>
) => {
  setGroups((prev) => [...getGroups([group]), ...prev]);
  const updatedTaxonomy = new Map(taxonomy);
  updatedTaxonomy.set(group.id, getTaxonomy([group]).get(group.id)!);
  setTaxonomy(updatedTaxonomy);
};

/**
 * //Check the searchGroups array. If the searchGroup is in there (check by id), replace it with this one.
 * //Otherwise, add this at the beginning.
 */
export const replaceOrAddToSearchGroups = (
  searchGroup: GroupDataFragment,
  groups: GroupBase[] | undefined,
  setGroups: (groups: GroupBase[]) => void,
  addGroup: (group: GroupDataFragment) => void,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  getTaxonomy: (groups: GroupTaxonomyFragment[] | null | undefined) => Map<string, TaxonomyGroup>
) => {
  const index = groups?.findIndex((sg) => sg.id === searchGroup.id);
  const searchToGroup = getGroups([searchGroup])[0];
  const updatedTaxonomy = new Map(taxonomy);
  /** Is this correct that we force this case? canAddChildren true and totalDescendents 0? */
  updatedTaxonomy.set(searchGroup.id, getTaxonomy([{ ...searchGroup, canAddChildren: true, totalDescendents: 0 }]).get(searchToGroup.id)!);
  setTaxonomy(updatedTaxonomy);
  if (index !== undefined && index !== -1) {
    const updatedGroups = _.cloneDeep(groups);
    if (searchToGroup && updatedGroups) {
      updatedGroups[index] = searchToGroup;
      setGroups(updatedGroups);
    }
  } else {
    addGroup(searchGroup);
  }
};

const refetchSimilarSentences = async (
  query: string,
  currentGroup: GroupBase | undefined,
  teamId: number,
  filterInput: FilterInput | undefined,
  page: number,
  pageSize: number,
  sentencesQuery: LazyQueryExecFunction<FeedbackSentencesQuery, FeedbackSentencesQueryVariables>,
  similarSentences: SentenceEntryFragment[],
  setSimilarSentences: (sentences: SentenceEntryFragment[]) => void,
  endOfDataSetCb?: () => void,
  cb?: () => void,
  queryChanged?: boolean
) => {
  if (currentGroup) {
    await sentencesQuery({
      variables: {
        teamId,
        sortByClusterId: currentGroup.id.toString(),
        take: pageSize,
        skip: page,
        filterInput: { queryString: query ? [query] : [] },
      },
      onCompleted(data) {
        if (data?.sentences?.length === 0) endOfDataSetCb?.();
        const currentSentenceIds = similarSentences.map((sentence) => sentence.id);
        setSimilarSentences([
          ...(queryChanged ? [] : similarSentences),
          ...(data.sentences?.filter((sentence) => !currentSentenceIds.includes(sentence.id)) ?? []),
        ]);
        cb?.();
      },
    });
  }
};

const handleCreateTag = (
  groupId: string,
  teamId: number,
  orgId: number,
  name: string,
  filterInput: FilterInput | undefined,
  createTag: CreateTagMutationFn,
  groupQuery: GetGroupQueryResult,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  cb?: () => void
) => {
  createTag({
    variables: { groupId, teamId, name },
    refetchQueries: [
      {
        query: DataForFiltersDocument,
        variables: { teamId: teamId, orgId: orgId },
      },
    ],

    async onCompleted(data) {
      const group = await groupQuery.refetch({ teamId, groupId, filterInput: filterInput });
      if (currentGroup) {
        currentGroup.tags = group.data.getGroup.tags as ITag[];
        setCurrentGroup(currentGroup);
      }
      cb && cb();
    },
  });
};

export const togglePinGroup = (
  groupId: string,
  teamId: number,
  groups: GroupBase[],
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase | undefined) => void,
  setGroups: (groups: GroupBase[]) => void,
  togglePinGroupMutation: TogglePinGroupMutationFn,
  cb?: () => void
) => {
  togglePinGroupMutation({
    variables: { teamId, groupId },
    onCompleted: async (data) => {
      /** why doesn't this use data.togglePinnedGroup.isPinnedByUser as the source of truth? */
      /** why are these different -
       * there's two sections for setting the current group
       * and both sections do this in a different way...
       *
       * why isn't it just
       * setCurrentGroup({...currentGroup, data.togglePinGroup.isPinnedByUser})
       *
       * we get this data back from the backend and it _should_ reflect the truth of the pinned state, right?
       * Ahhh... cuz the data on the backend does not reflect the pinned state.
       */
      let pinResult = undefined;
      if (taxonomy.get(groupId)) {
        const updatedTaxonomy = new Map(taxonomy);
        const current = taxonomy.get(groupId)!;
        pinResult = !current.pinnedByUser;
        updatedTaxonomy.set(groupId, { ...current, pinnedByUser: !current.pinnedByUser });
        setTaxonomy(updatedTaxonomy);

        if (currentGroup) {
          setCurrentGroup({ ...currentGroup, pinnedByUser: pinResult });
        }
      }
      const index = groups.findIndex((g) => g.id === data.togglePinGroup.id);
      if (index !== -1) {
        const group = groups[index];
        group.pinnedByUser = !group.pinnedByUser;
        pinResult = group.pinnedByUser;
        groups[index] = group;
        setGroups(groups);
        if (currentGroup) {
          setCurrentGroup({ ...currentGroup, pinnedByUser: pinResult ?? data.togglePinGroup.isPinnedByUser });
        }
      }

      if (pinResult != null) {
        writeToastMessage(`Group ${pinResult ? 'Pinned' : 'Unpinned'}`);
      }
      cb && cb();
    },
  });
};

const handleTagGroup = (
  groupId: string,
  tagId: number,
  teamId: number,
  filterInput: FilterInput | undefined,
  tagGroup: TagGroupMutationFn,
  groupQuery: GetGroupQueryResult,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  cb?: () => void
) => {
  tagGroup({
    variables: { groupId, teamId, tagId },
    async onCompleted(data) {
      const group = await groupQuery.refetch({ teamId, groupId, filterInput: filterInput });
      if (currentGroup) {
        currentGroup.tags = group.data.getGroup.tags as ITag[];
        setCurrentGroup(currentGroup);
      }
      cb && cb();
    },
  });
};

export const handleRemoveTag = (
  groupId: string,
  tagId: number,
  teamId: number,
  removeTag: RemoveTagFromGroupMutationFn,
  groupQuery: GetGroupQueryResult,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  cb?: () => void
) => {
  removeTag({
    variables: { groupId, teamId, tagId },
    async onCompleted(data) {
      if (currentGroup) {
        const index = currentGroup.tags?.findIndex((tag) => tag.id === tagId) ?? -1;
        if (index !== -1) {
          currentGroup.tags?.splice(index, 1);
          setCurrentGroup(currentGroup);
        }
      }
      cb && cb();
    },
  });
};

export const discardGroup = async (
  groupId: string,
  teamId: number,
  groups: GroupBase[],
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  setGroups: (groups: GroupBase[]) => void,
  editGroup: EditGroupMutationFn,
  setCurrentGroup: (group?: GroupBase) => void,
  cb?: () => void
): Promise<GroupDependencies | void> => {
  const { data } = await editGroup({
    variables: { groupId, teamId: teamId, input: { status: Group_Status.Archived }, filterInput: {} },
  });
  if (!data?.editGroup.success) {
    if (data?.editGroup.dependencies) return data?.editGroup.dependencies as GroupDependencies;
    toast.error('Error deleting group');
    return;
  }

  const newGroups = groups?.filter((group) => group.id !== groupId);
  const parentId = taxonomy.get(groupId)?.parentId;
  const updatedTaxonomy = new Map(taxonomy);
  if (parentId && updatedTaxonomy.get(parentId) != null) {
    const parent = updatedTaxonomy.get(parentId) as TaxonomyGroup;
    const children = parent.children?.filter((c) => c.id !== groupId) ?? [];
    updatedTaxonomy.set(parentId, { ...parent, children: children, totalDescendents: parent.totalDescendents - 1 });
  }
  updatedTaxonomy.delete(groupId);
  setTaxonomy(updatedTaxonomy);
  setGroups(newGroups);
  writeToastMessage('Group deleted successfully');
  cb && cb();
};
/**
 * Below are functions that don't need state variable access in groupHook
 */

export const sortGroups = (a: GroupBase, b: GroupBase) => {
  if (a.processing !== b.processing) {
    return b.date - a.date;
  } else {
    return b.totalEntries - a.totalEntries;
  }
};

/**
 * converts SentenceFragment into a Sentence type
 * @param sentence
 * @returns
 */

export const getSentence = (sentence: SentenceEntryFragment | GroupSentenceDataFragment | undefined | null): SentenceEntry | undefined => {
  if (sentence == null) return undefined;

  return {
    id: sentence.id,
    text: sentence?.text,
    is_distillate: sentence.__typename === 'Sentence' ? false : (sentence as SentenceEntryFragment).is_distillate,
    is_title: (sentence as SentenceEntryFragment).is_title,
    entry: (sentence as SentenceEntryFragment).entry,
    groupId: (sentence as GroupSentenceDataFragment).groupId,
  };
};
/**
 * converts EntryFragment into an Entry type
 * @param entry
 * @returns
 */
const getEntry = (entry: EntryFragment | null | undefined): EntryFragment | undefined => {
  if (entry == null) return undefined;
  return {
    ...entry,
  };
};

/**
 * Converts search group data into a time series chart
 * @param searchId
 * @param searchTitle
 * @param seriesData
 * @returns
 */
const getTimeSeriesForGroup = (searchId: number, searchTitle: string, seriesData: IChartableItem[] | undefined): IChartSeries => {
  return {
    seriesId: searchId,
    seriesTitle: searchTitle ?? '',
    totalValue: seriesData?.length ?? 0,
    seriesData: seriesData ?? [],
  };
};

export const getGroupSentences = (groups: GetGroupSentencesQuery) => {
  const group = groups?.getGroup;
  if (group == null) {
    return { sentences: [], entries: [] };
  }
  return {
    sentences: group?.groupEntries?.flatMap((sm) => sm.mappedSentences.map((sentence) => getSentence(sentence))).filter((item) => item) as Sentence[],
    entries: group?.groupEntries
      ?.flatMap((sm) => sm.mappedSentences.map((sentence) => getEntry(sentence.entry)))
      .filter((item, index, self) => index === self.findIndex((obj) => obj && item && obj.id === item.id)) as EntryFragment[],
  };
};

/**
 * Converts groups into an IGroup
 * @param groups
 * @returns
 */
export const getGroups = (groups: (GroupDataFragment & { insight?: GroupInsightFragment | undefined | null })[] | null | undefined): GroupBase[] => {
  if (groups == null) {
    return [];
  }
  return groups.map((group) => {
    return new GroupFull({
      type: group.type,
      id: group.id,
      ownerId: group.owner?.id,
      isExactMatch: group.isExactMatch,
      //@ts-ignore
      isNew: group.isNew,
      centroid: group.centroidText,
      creator: { isUnwrapGenerated: group.creator?.isUnwrapGenerated ?? true, creatorEmail: group.creator?.user?.email },
      pinnedByUser: group.isPinnedByUser,
      title: group.title,
      sentences: group?.groupEntries?.flatMap((entry) => entry.mappedSentences.map((s) => getSentence(s))).filter((item) => item) as SentenceEntry[],
      entries: group?.groupEntries
        ?.flatMap((entry) => entry.mappedSentences.map((s) => getEntry(s.entry)))
        .filter((item, index, self) => index === self.findIndex((obj) => obj && item && obj.id === item.id)) as EntryFragment[],
      aggregateData: group.mentionsOverTime?.aggregateData[0],
      normalizedData: group.mentionsOverTime?.normalizedData[0],
      denominator: group.statistics.denominator.denominatorUnfiltered,
      tooltipLabels: group.mentionsOverTime?.tooltipLabels,
      chartLabels: group.mentionsOverTime?.chartLabels,
      filterInput: group.mentionsOverTime?.filterInput
        ? { startDate: new Date(group?.mentionsOverTime?.filterInput.startDate), endDate: new Date(group.mentionsOverTime?.filterInput.endDate) }
        : {},
      relativeShare: group.statistics.denominator.denominatorFiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorFiltered : 0,
      relativeShareFull:
        group.statistics.denominator.denominatorUnfiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorUnfiltered : 0,
      status: group.status,
      totalEntries: group.uniqueEntries,
      processing: group.processing,
      progress: group.progress,
      date: group.dateCreated ?? 0,
      totalDescendents: group.totalDescendents,
      insight: group.insight,
      tags: group.tags
        ? group.tags.map((tag) => {
            return { id: tag.id, name: tag.name };
          })
        : undefined,
    });
  });
};

/**
 * Edits the title of the group at the specified id
 * @param groupId
 * @param teamId
 * @param title
 * @param groups
 * @param groupQuery
 * @param denominator
 * @param editGroup
 * @param setGroups
 */
const editTitle = (
  groupId: string,
  teamId: number,
  title: string,
  groups: GroupBase[],
  groupQuery: GetGroupQueryResult,
  filterInput: FilterInput | undefined,
  editGroup: EditGroupMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void
) => {
  editGroup({
    variables: { teamId, groupId, input: { title }, filterInput: filterInput ?? {} },
    async onCompleted(data) {
      const groupData = (await groupQuery.refetch({ teamId, groupId, filterInput: filterInput })).data.getGroup;
      const updatedGroups = _.cloneDeep(groups);
      const index = updatedGroups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([groupData])[0];
        setGroups(updatedGroups);
      }
      if (taxonomy.get(groupId) !== undefined) {
        const updatedTaxonomy = new Map(taxonomy);
        updatedTaxonomy.set(groupId, {
          ...taxonomy.get(groupId)!,
          title: title,
        });
        setTaxonomy(updatedTaxonomy);
      }
    },
  });
};
/**
 * Updates owner to the specified user ID.
 * @param groupId
 * @param teamId
 * @param orgId
 * @param userId
 * @param groups
 * @param groupQuery
 * @param denominator
 * @param updateGroupOwner
 * @param setGroups
 */
const updateOwner = (
  groupId: string,
  teamId: number,
  orgId: number,
  userId: number,
  groups: GroupBase[],
  groupQuery: GetGroupQueryResult,
  filterInput: FilterInput | undefined,
  updateGroupOwner: UpdateGroupOwnerMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  currentGroup: GroupFull | undefined,
  setCurrentGroup: (group: GroupFull) => void,
  cb?: () => void
) => {
  updateGroupOwner({
    variables: { groupId, teamId, orgId, userId },
    async onCompleted(data) {
      const groupData = (await groupQuery.refetch({ teamId, groupId, filterInput: filterInput })).data.getGroup;
      const updatedGroups = _.cloneDeep(groups);
      const index = groups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([groupData])[0];
        setGroups(updatedGroups);
        if (currentGroup) {
          currentGroup.ownerId = groupData.owner?.id;
          setCurrentGroup(currentGroup);
        }
      }
      writeToastMessage('Owner updated successfully');
      cb && cb();
    },
  });
};
/**
 * Removes the current group owner at specified group ID.
 * @param groupId
 * @param teamId
 * @param groups
 * @param groupQuery
 * @param removeGroupOwner
 * @param setGroups
 */
const removeOwner = (
  groupId: string,
  teamId: number,
  groups: GroupBase[],
  groupQuery: GetGroupQueryResult,
  filterInput: FilterInput | undefined,
  removeGroupOwner: RemoveGroupOwnerMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  currentGroup: GroupFull | undefined,
  setCurrentGroup: (group: GroupFull) => void,
  cb?: () => void
) => {
  removeGroupOwner({
    variables: { groupId, teamId },
    async onCompleted(data) {
      const groupData = (await groupQuery.refetch({ teamId, groupId, filterInput: filterInput })).data.getGroup;
      const updatedGroups = _.cloneDeep(groups);
      const index = groups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([groupData])[0];
        setGroups(updatedGroups);
        if (currentGroup) {
          currentGroup.ownerId = groupData.owner?.id;
          setCurrentGroup(currentGroup);
        }
      }
      writeToastMessage('Owner removed successfully');
      cb && cb();
    },
  });
};

/**
 * Creates a filter set containing GroupTitle, startDate, and endDate and copies that filter to the clipboard
 * @param groupId
 * @param teamId
 * @param orgId
 * @param urlKey
 */
export const copyGroupLink = (groupId: string, filterInput: FilterInput, teamId: number, orgId: number, urlKey?: string) => {
  const groupLink = getGroupLink(groupId, filterInput, teamId, orgId, urlKey);
  navigator.clipboard.writeText(groupLink);
  return groupLink;
};

export const getGroupLink = (groupId: string, filterInput: FilterInput, teamId: number, orgId: number, urlKey?: string, exploreUrl?: boolean) => {
  const filter: FilterInput = {
    ...filterInput,
    startDate: filterInput.startDate,
    endDate: !moment().endOf('day').isSame(filterInput.endDate) ? filterInput.endDate : undefined,
  };
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);

  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set(urlKey ?? 'filters', encodeURIComponent(JSON.stringify(filter)));

  // if on homepage, then change pathname to explore page
  // if on group page, don't change pathname
  const pathname = exploreUrl
    ? AppRoutes.v3FullPath.explore + '/group/' + groupId
    : window.location.pathname.replace('home', 'explore') + (window.location.pathname.includes('/group') ? '' : '/group/' + groupId);
  const newUrl = window.location.protocol + '//' + window.location.host + pathname + '?' + urlParams.toString();
  return newUrl;
};

export const writeToastError = (message: string) => {
  toast.error(message);
};
export const writeToastMessage = (message: string) => {
  toast.success(message);
};

export const getChildCandidates = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: string,
  query: string,
  children: GroupMembershipFragment[],
  getChildCandidatesQuery: LazyQueryExecFunction<GetChildCandidatesQuery, GetChildCandidatesQueryVariables>,
  setChildCandidates: (childCandidates: TaxonomyGroup[]) => void,
  cb?: () => void
) => {
  await getChildCandidatesQuery({
    variables: {
      teamId,
      parentGroupId: groupId,
      groupTitleSearchString: query,
    },
    onCompleted(data) {
      const childCandidates = Array.from(getTaxonomyMap(data.getChildCandidates).values());
      setChildCandidates(childCandidates.filter((c) => c.id !== groupId && c.canAddChildren && !children.find((child) => child.id === c.id)));

      cb?.();
    },
  });
};

export const getChildrenLight = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: string,
  getChildren: LazyQueryExecFunction<GetChildrenLightQuery, GetChildrenLightQueryVariables>,
  setChildren: (children: GroupMembershipFragment[]) => void,
  cb?: () => void
) => {
  await getChildren({
    variables: {
      teamId,
      parentGroupId: groupId,
    },
    onCompleted(data) {
      setChildren(data.getChildrenLight);
      cb?.();
    },
  });
};

/**
 * This opens generates children for the next level of the taxonomy
 * @param teamId
 * @param filterInput
 * @param groupId
 * @param taxonomy
 * @param setTaxonomy
 * @param getChildren
 */

export const openParent = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: string,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  getChildren: LazyQueryExecFunction<GetChildrenLightQuery, GetChildrenLightQueryVariables>,
  cb?: () => void
) => {
  // open/close parent
  if (taxonomy.get(groupId) != null) {
    const updatedTaxonomy = new Map(taxonomy);
    updatedTaxonomy.set(groupId, {
      ...taxonomy.get(groupId)!,
      showChildren: !taxonomy.get(groupId)!.showChildren ?? false,
    });
    setTaxonomy(updatedTaxonomy);
    // Save all open ids to localstorage
    const openIds = [...Array.from(updatedTaxonomy.values())].filter((group) => group.showChildren).map((group) => group.id);
    saveGroupIdsToLocalStorage(openIds);

    cb?.();
    return;
  }
  cb?.();
};

export const getChildrenToAssign = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: string,
  taxonomy: Map<string, TaxonomyGroup>,
  setChildrenToAssign: (childrenToAssign: TaxonomyGroup[]) => void,
  getChildrenToAssign: LazyQueryExecFunction<GetChildrenToAssignQuery, GetChildrenToAssignQueryVariables>,
  cb?: () => void
) => {
  await getChildrenToAssign({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      groupId: groupId,
    },
    onError(err) {
      writeToastError(err.message);
      cb?.();
    },

    onCompleted(data) {
      const children = Array.from(getTaxonomyMap(data.getChildrenToAssign).values());
      if (children) {
        const currentGroup = taxonomy.get(groupId);
        if (currentGroup) {
          const childrenIds = currentGroup.children?.map((c) => c.id);
          setChildrenToAssign(children.filter((c) => childrenIds?.includes(c.id) === false));
        } else {
          setChildrenToAssign(children);
        }
        cb?.();
        return;
      }
    },
  });
};

export const assignChild = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: string,
  childGroupId: string,
  taxonomy: Map<string, TaxonomyGroup>,
  childCandidates: TaxonomyGroup[],
  setChildCandidates: (childCandidates: TaxonomyGroup[]) => void,
  children: GroupMembershipFragment[],
  setChildren: (childCandidates: GroupMembershipFragment[]) => void,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  assignChild: AssignChildMutationFn,
  getTaxonomyGroup: LazyQueryExecFunction<GetTaxonomyGroupQuery, GetTaxonomyGroupQueryVariables>,
  type?: 'Parent' | 'Child',
  cb?: () => void
) => {
  await assignChild({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: parentGroupId,
      childGroupId: childGroupId,
    },
    onError(err) {
      writeToastError(err.message);
      cb?.();
    },

    async onCompleted() {
      const parent = taxonomy.get(parentGroupId);
      let child = childCandidates.find((ch) => ch.id === childGroupId)
        ? getTaxonomyMap([childCandidates.find((ch) => ch.id === childGroupId)!]).get(childGroupId)!
        : taxonomy.get(childGroupId);

      if (!child && parent) {
        await getTaxonomyGroup({
          variables: {
            filterInput,
            teamId,
            groupId: childGroupId,
          },
          onCompleted(data) {
            const group = data.getGroup;
            child = new TaxonomyGroup({
              ...group,
              showChildren: false,
              parentId: parentGroupId,
              children: [], //Not found in taxonomy means it has no children.
              totalEntries: group.uniqueEntries,
              denominator: group.statistics.denominator.denominatorUnfiltered,
              pinnedByUser: group.isPinnedByUser,
              centroid: group.centroidText,
              sentences: [],
              entries: [],
              date: group.dateCreated ?? 0,
              trending: group.trending,
            });
          },
        });
      }

      const parentChildren = [...(parent?.children ?? []), ...[child ? child : taxonomy.get(childGroupId)!]];
      //If there's no parent in the current taxonomy, it's because it's outside the current filters. We don't update the current taxonomy.
      if (parent) {
        const updatedTaxonomy = new Map(taxonomy);
        const oldParent = taxonomy.get(updatedTaxonomy.get(childGroupId)?.parentId ?? '-1');
        if (parentGroupId && taxonomy.get(parentGroupId)) {
          updatedTaxonomy.set(parentGroupId, {
            ...parent!,
            children: parentChildren,
            totalDescendents: parent.totalDescendents + child!.totalDescendents + 1,
          });
        }
        if (oldParent) {
          updatedTaxonomy.set(oldParent.id, {
            ...oldParent,
            totalDescendents: oldParent.totalDescendents! - child!.totalDescendents - 1,
            showChildren: (oldParent.totalDescendents ?? 0) - child!.totalDescendents - 1 > 0,
            children: (oldParent.children ?? [])
              .filter((c) => c.id !== childGroupId && c.id !== parentGroupId && c.id !== oldParent.id)
              .filter((c, i, a) => a.findIndex((ch) => ch.id === c.id) === i),
          });
        }
        updatedTaxonomy.set(childGroupId, {
          ...child!,
          children: updatedTaxonomy.get(childGroupId)?.children ?? child?.children ?? [],
          parentId: parentGroupId,
        });
        setTaxonomy(updatedTaxonomy);
      }
      setChildCandidates(childCandidates.filter((c) => c.id !== childGroupId));
      const parentChildrenAsGroupLight = parentChildren.map((grp) => ({ ...grp, teamId })) as GroupMembershipFragment[];
      if (type === 'Child') setChildren(parentChildrenAsGroupLight);
      cb?.();
      writeToastMessage(` ${type ? type : 'Child'} successfully assigned!`);
    },
  });
};

export const removeChildFromParent = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: string,
  childGroupId: string,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  removeChild: RemoveChildMutationFn,
  cb?: () => void
) => {
  await removeChild({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: parentGroupId,
      childGroupId: childGroupId,
    },
    onCompleted() {
      const updatedTaxonomy = new Map(taxonomy);
      const parent = taxonomy.get(parentGroupId);
      const child = taxonomy.get(childGroupId)!;
      if (parent) {
        updatedTaxonomy.set(parentGroupId, {
          ...parent!,
          showChildren: parent!.totalDescendents - child.totalDescendents - 1 > 0,
          totalDescendents: parent!.totalDescendents - child.totalDescendents - 1,
          children: (taxonomy.get(parentGroupId)?.children ?? []).filter((c) => c.id != childGroupId),
        });
      }
      if (child) {
        updatedTaxonomy.set(childGroupId, {
          ...taxonomy.get(childGroupId)!,
          parentId: null,
        });
      }
      setTaxonomy(updatedTaxonomy);
      cb?.();
      writeToastMessage('Child successfully removed!');
    },
  });
};

export const deleteChild = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: string,
  childGroupId: string,
  taxonomy: Map<string, TaxonomyGroup>,
  children: GroupMembershipFragment[],
  setChildren: (childCandidates: GroupMembershipFragment[]) => void,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  deleteChild: DeleteChildMutationFn,
  cb?: () => void
) => {
  await deleteChild({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: parentGroupId,
      childGroupId: childGroupId,
    },
    onCompleted(data) {
      const currentParent = taxonomy.get(parentGroupId);
      const currentChild = taxonomy.get(childGroupId);
      const updatedTaxonomy = new Map(taxonomy);
      updatedTaxonomy.set(parentGroupId, {
        ...currentParent!,
        children: currentParent?.children?.filter((c) => c.id !== childGroupId) ?? [],
        totalDescendents: currentParent!.totalDescendents - (currentChild?.totalDescendents ?? 0) - 1,
        showChildren: currentParent!.totalDescendents - 1 > 0,
      });
      if (updatedTaxonomy.get(childGroupId)) {
        updatedTaxonomy.set(childGroupId, {
          ...taxonomy.get(childGroupId)!,
          parentId: null,
        });
      }
      const childrenAsGroupLight = [...(currentParent?.children ?? [])]
        ?.map((grp) => ({ ...grp, teamId }))
        ?.filter((c) => c.id !== childGroupId) as GroupMembershipFragment[];
      setTaxonomy(updatedTaxonomy);
      setChildren(childrenAsGroupLight ?? []);
      cb?.();
      writeToastMessage('Child successfully removed!');
    },
  });
};
export const assignChildren = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: string,
  taxonomy: Map<string, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<string, TaxonomyGroup>) => void,
  assignChildren: AssignChildrenMutationFn,
  childGroupIds: string[],
  children: GroupMembershipFragment[],
  setChildren: (childCandidates: GroupMembershipFragment[]) => void,
  childrenToAssign: TaxonomyGroup[],
  setChildrenToAssign: (childCandidates: TaxonomyGroup[]) => void,
  cb?: () => void
) => {
  writeToastMessage('Assigning Children Automatically...');
  await assignChildren({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      groupId: parentGroupId,
      childGroupIds: childGroupIds,
    },
    onError(err) {
      writeToastMessage(err.message);
      cb?.();
    },
    onCompleted(data) {
      const parent = taxonomy.get(parentGroupId);
      const parentChildren = [...childrenToAssign];
      if (parentChildren.length === 0) {
        cb?.();
        writeToastMessage('No Children to Assign');
        return;
      }
      if (taxonomy.get(parentGroupId)) {
        const childrenTaxonomy: Map<string, TaxonomyGroup> = getTaxonomyMap(parentChildren ?? [], undefined, parentGroupId);
        const updatedTaxonomy = new Map([...Array.from(taxonomy.entries()), ...Array.from(childrenTaxonomy.entries())]);
        updatedTaxonomy.set(parentGroupId, {
          ...parent!,
          children: [...parentChildren, ...(parent!.children ?? [])],
          totalDescendents: parent!.totalDescendents + childrenToAssign.length,
        });
        setTaxonomy(updatedTaxonomy);
      }

      const parentChildrenAsGroupLight = parentChildren.map((grp) => ({ ...grp, teamId })) as GroupMembershipFragment[];
      setChildren([...children, ...parentChildrenAsGroupLight]);
      setChildrenToAssign(childrenToAssign.filter((c) => !parentChildren.map((pc) => pc.id).includes(c.id)));
      writeToastMessage('Children successfully assigned!');
      cb?.();
    },
  });
};

export const GROUP_ENTRIES_PAGE_SIZE = 20;
export const getCurrentGroup = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: string,
  getGroup: LazyQueryExecFunction<GetGroupQuery, GetGroupQueryVariables>,
  setCurrentGroup: (group: GroupBase) => void,
  sentencesTake?: number,
  cb?: () => void,
  redirect?: () => void
) => {
  const initialLoadTime = performance.now();
  await getGroup({
    fetchPolicy: 'no-cache',
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      groupId: groupId,
      sentencesSkip: 0,
      sentencesTake: GROUP_ENTRIES_PAGE_SIZE,
      includeDescendantsOnMappings: true,
    },
    onCompleted(data) {
      const group = data.getGroup;
      setCurrentGroup(getGroups([group])[0]);
      // this is only ever used on the explore page to fetch the entire group on the taxonomy
      const event = getTotalGroupLoadEvent({ view: 'taxonomy', duration: initialLoadTime });
      window.dispatchEvent(event);
      cb?.();
    },
    onError(err) {
      if (err.message.includes('Cannot find search')) {
        redirect?.();
      }
      writeToastError("Can't find the selected group. Make sure the link is correct or switch to the corresponding view.");
      cb?.();
    },
  });
};
