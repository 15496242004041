import { BellAlertIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import { GroupFull } from '../../../../../v2/hooks/GroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useState } from 'react';
import { GroupSubscriptionPopover } from '../../../Popovers/GroupSubscriptionPopover';

export function GroupSubscriptionsButton(props: { group: GroupFull; buttonRounding?: 'left' | 'right' }) {
  const [popoverKey, setPopoverKey] = useState<number>(0);

  return (
    <div
      id={`group-subscriptions`}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <GroupSubscriptionPopover
        groupId={props.group?.id ?? -1}
        updateKey={setPopoverKey}
        key={popoverKey}
        customIconElem={
          <ToolbarBaseButton
            id="toolbar-group-subscriptions-button"
            icon={<BellAlertIcon className="h-5 w-5 stroke-2" />}
            label={'Configure Subscriptions'}
            buttonRounding={props.buttonRounding}
          />
        }
      />
    </div>
  );
}
