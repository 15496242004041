import { useEffect, useState } from 'react';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import wizardImg from '../../../assets/wizard_dribbble.png';
import React from 'react';
import { FeedbackEntriesQuery, GptResponseType } from '../../../generated/graphql';
import { ArrayElement } from '../../../utilities';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { TextArea } from './ReplyTextArea';
import { selector } from '../../pages/ReplyingPage';
import TemplateDropDown from './TemplateDropdown';
import { ReplyHook } from '../../hooks/ReplyHook';
import toast from 'react-hot-toast';

interface CraftGeneratedReply {
  replyHook: ReplyHook;
  selectedItem: IDropDownItem | undefined;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
}

export const CraftGeneralReplyRow = ({ replyHook, selectedItem, setSelectedItem }: CraftGeneratedReply) => {
  const [text, setText] = useState('');

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const newValue = event.target.value;
    setText(newValue);
  }

  useEffect(() => {
    if (selectedItem?.value) {
      setText(selectedItem.value as string);
    }
  }, [selectedItem]);

  return (
    <div className="grid grid-cols-10 gap-x-6 w-full">
      <div className="col-span-5 flex flex-col gap-y-2">
        <div className="flex flex-row gap-x-2 items-center">
          <img className="w-11 h-11" src={wizardImg} />
          <h1 className="font-bold text-3xl">Craft Your Reply</h1>
        </div>
        <div className="flex flex-col gap-y-2">
          <h2 className="text-sm">Write a prompt explaining your latest update, feature, or bug fix. For example, "Dark Mode is now available on Android".</h2>
          <h2 className="text-sm">Our AI will generate a variety of engaging and personalized responses for you to send to your customers.</h2>
          <h2 className="text-sm">You can also generate Smart Replies per feedback entry, which will address the exact feedback your customer gave!</h2>
        </div>
      </div>
      <div className="flex col-span-5">
        <TextArea
          rows={4}
          darkMode
          handleTextChange={handleTextChange}
          text={text}
          placeholder="Type your reply...or use our AI-powered reply crafting feature below."
          buttonRow={
            <PromptButtonRow
              loadingGenerateReplies={replyHook.generatingGeneralReplies}
              copyReply={() => replyHook.handleCopyReplies(text)}
              generateReply={async () => {
                replyHook.handleGenerateReplies(text);
              }}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          }
        />
      </div>
    </div>
  );
};

const PromptButtonRow = ({
  loadingGenerateReplies,
  copyReply,
  generateReply,
  selectedItem,
  setSelectedItem,
}: {
  loadingGenerateReplies: boolean;
  copyReply: () => void;
  generateReply: () => void;
  selectedItem: IDropDownItem | undefined;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
}) => {
  return (
    <div className="flex flex-row items-center justify-between  w-full">
      <TemplateDropDown dropDownData={selector} setSelectedItem={(item) => setSelectedItem(item)} selectedItem={selectedItem} darkMode />
      <div className="flex flex-row gap-x-3">
        <Button
          loadingConfirm={loadingGenerateReplies}
          loadingText="Generating smart replies..."
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={() => generateReply()}
          text="Generate Smart Replies"
          data-testid="generate-smart-replies-button"
        />
        <Button
          loadingText="Copy Text"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={() => copyReply()}
          text="Copy Text"
          data-testid="copy-text-button"
        />
      </div>
    </div>
  );
};
