import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { classNames } from '../v2/util';

interface InlineEditableTextAreaProps {
  textAreaId?: string;
  value: string;
  additionalClassNames?: string;
  ignorePadding?: boolean;
  // this only fires when someone presses the enter key
  onEditComplete: (value: string) => void;
  editable?: boolean;
  placeholder?: string;
  textClassName?: string;
}

const InlineEditableTextArea = ({
  textAreaId,
  value,
  additionalClassNames,
  onEditComplete,
  editable = true,
  ignorePadding,
  placeholder,
  textClassName,
  ...props
}: InlineEditableTextAreaProps) => {
  const [textValue, setTextValue] = useState(value);
  const { ref, inView } = useInView({ threshold: 0 });
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  /**
   * If the text changes OR if the component wasn't on screen when it was initially drawn. Reset the textArea component's height based on
   * text size
   */
  const resizeTextArea = () => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      let scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  };
  useEffect(() => {
    resizeTextArea();
  }, [textValue, inView]);

  useEffect(() => {
    window.addEventListener('resize', resizeTextArea);
    return () => {
      window.removeEventListener('resize', resizeTextArea);
    };
  }, []);

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!editable) return;
    if (event.key === 'Enter' || event.key === 'Escape') {
      // @ts-ignore
      event.target.blur();
    }
    if (event.key === 'Escape') {
      setTextValue(value);
    }
  };
  return (
    <>
      {editable ? (
        <div ref={ref} className={`${ignorePadding ? 'flex' : 'py-2'} w-full`} {...props}>
          <textarea
            spellCheck="false"
            id={textAreaId ?? ''}
            data-testid={textAreaId ?? ''}
            rows={1}
            onClick={(e) => e.stopPropagation()}
            disabled={!editable}
            autoFocus={false}
            ref={textAreaRef}
            className={classNames(
              'scroll-none -ml-1 mt-0 w-full resize-none overflow-hidden border-0 leading-tight px-1 py-0 focus:border-2 focus:border-blueberry focus:ring-0 placeholder:text-gray-400',
              editable ? 'hover:cursor-text hover:bg-blueberry-lighter' : '',
              textClassName ?? 'text-lg',
              additionalClassNames,
              !additionalClassNames?.includes('font-') ? 'font-semibold' : ''
            )}
            aria-label="text"
            value={textValue}
            onKeyDown={onKeyDown}
            onBlur={() => onEditComplete(textValue)}
            onChange={(e) => {
              setTextValue(e.target.value);
            }}
            placeholder={placeholder}
          />
        </div>
      ) : (
        <div className="scroll-none w-full resize-none overflow-hidden border-0 bg-transparent line-clamp-1  focus:border-2 focus:border-blueberry focus:ring-0">
          <h3 className={classNames(textClassName ?? 'text-lg font-semibold')}>{textValue}</h3>
        </div>
      )}
    </>
  );
};

export default InlineEditableTextArea;
