import { useNavigate } from 'react-router-dom';
import InlineEditableTextArea from '../../../../baseComponents/InlineEditableTextArea';
import { useBoardState, useBoardDispatch } from '../../../../context/boardContext';
import { useUpdateBoardTitleMutation, useUpdateBoardDescriptionMutation } from '../../../../generated/graphql';
import { BoardActionTypes } from '../../../../reducers/boards/boardReducer';
import { AppRoutes } from '../../../../Routes';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import Button, { ButtonVariant, ButtonShape, ButtonSize } from '../../../baseComponents/Button';
import { BoardToolbar } from '../../../components/boards/board/BoardToolbar';
import toast from 'react-hot-toast';

export const BoardHeaderSection = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const state = useBoardState();
  const dispatch = useBoardDispatch();
  const navigate = useNavigate();

  const [updateBoardTitle, _] = useUpdateBoardTitleMutation();
  const [updateBoardDescription, _2] = useUpdateBoardDescriptionMutation();

  async function handleSaveBoardTitle(value: string) {
    await updateBoardTitle({
      variables: { boardId: state.board.id, title: value, teamId },
      onCompleted: () => {
        toast.success('Title updated');
        dispatch({ type: BoardActionTypes.SetTitle, payload: { title: value } });
      },
      refetchQueries: ['GetBoards'],
    });
  }

  async function handleSaveBoardDescription(value: string) {
    await updateBoardDescription({
      variables: { boardId: state.board.id, description: value, teamId },
      onCompleted: () => {
        toast.success('Description updated');
        dispatch({ type: BoardActionTypes.SetDescription, payload: { description: value } });
      },
      refetchQueries: ['GetBoards'],
    });
  }

  return (
    <div className="flex flex-row gap-x-1">
      <div className="flex flex-col w-4/5 gap-y-2">
        <div className="flex flex-row gap-x-4 items-center">
          <Button
            id="back-button"
            variant={ButtonVariant.Tertiary}
            shape={ButtonShape.Pill}
            size={ButtonSize.Small}
            text="Back"
            onClick={() => navigate(AppRoutes.v3FullPath.boards)}
            icon={<p>{'<'}</p>}
            iconPosition="left"
          />
          <InlineEditableTextArea
            ignorePadding
            textAreaId="board-title-text-area"
            value={state.board.title ?? ''}
            onEditComplete={handleSaveBoardTitle}
            additionalClassNames={
              'text-3xl xl:text-4xl text-blueberry h-auto font-semibold focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
            }
          />
        </div>
        {state.board.description ? (
          <InlineEditableTextArea
            ignorePadding
            textAreaId="board-description-text-area"
            value={state.board.description ?? ''}
            onEditComplete={handleSaveBoardDescription}
            additionalClassNames={
              'text-sm text-blueberry h-auto font-normal focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
            }
          />
        ) : null}
      </div>
      <div className="flex flex-row w-1/5 items-start justify-end pt-1">
        <BoardToolbar />
      </div>
    </div>
  );
};
