import {Cog6ToothIcon, BookmarkIcon as BookmarkIconSolid, LinkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoardState, useBoardDispatch } from '../../../../context/boardContext';
import { useDeleteBoardMutation, useCloneBoardMutation, useTogglePinBoardMutation } from '../../../../generated/graphql';
import { BoardActionTypes } from '../../../../reducers/boards/boardReducer';
import { AppRoutes } from '../../../../Routes';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../../../baseComponents/SettingsMenu';
import { ToolbarBaseButton } from '../../groups/toolbar/buttons/ToolbarBaseButton';
import { ButtonGroup } from '../../groups/toolbar/GroupToolbar';
import YesCancelModal from '../../Modals/YesCancelModal';
import toast from 'react-hot-toast';
import { BookmarkIcon, Square2StackIcon, TrashIcon } from '@heroicons/react/24/outline';

export const BoardToolbar = () => {
  const { board } = useBoardState();
  const navigate = useNavigate();
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const [deleteBoardModalOpen, setDeleteBoardModalOpen] = useState<boolean>(false);
  const [cloneBoardModalOpen, setCloneBoardModalOpen] = useState<boolean>(false);

  const [deleteBoardMutation, deleteBoardMutationRes] = useDeleteBoardMutation();
  const [cloneBoardMutation, cloneBoardMutationRes] = useCloneBoardMutation();

  async function handleDeleteBoard() {
    await deleteBoardMutation({
      variables: {
        teamId,
        boardId: board!.id,
      },
      onCompleted() {
        navigate(AppRoutes.v3FullPath.boards);
        setDeleteBoardModalOpen(false);
        toast.success('Board deleted');
      },
    });
  }

  async function handleCloneBoard() {
    await cloneBoardMutation({
      variables: {
        teamId,
        boardId: board!.id,
      },
      onCompleted() {
        navigate(AppRoutes.v3FullPath.boards);
        toast.success('Board duplicated');
      },
    });
  }

  const settings: ISettingsItem[] = [
    {
      name: 'Duplicate board',
      id: 0,
      group: 'actions',
      htmlId: 'duplicate-board',
      icon: <Square2StackIcon className="h-5 w-5" />,
      onClick: () => setCloneBoardModalOpen(true),
    },
    {
      name: 'Delete board',
      id: 1,
      group: 'actions',
      htmlId: 'delete-board',
      icon: <TrashIcon className="h-5 w-5" />,
      onClick: () => setDeleteBoardModalOpen(true),
      textColor: 'failure',
    },
  ];

  return (
    <div className="flex flex-row gap-x-2">
      <YesCancelModal
        text={'Are you sure you want to delete this board?'}
        subtext={'All widgets inside this board will be deleted. This action cannot be undone.'}
        confirmText="Yes, delete"
        callbackModal={setDeleteBoardModalOpen}
        modalOpen={deleteBoardModalOpen}
        idToPass={board!.id}
        confirmButton={handleDeleteBoard}
        closeOnConfirm={false}
        understandCheck={false}
        loadingConfirm={deleteBoardMutationRes.loading}
      />
      <YesCancelModal
        text={'You are about to duplicate this board'}
        subtext={'This will duplicate all its widgets, together with their configurations.'}
        confirmText="Confirm"
        callbackModal={setCloneBoardModalOpen}
        modalOpen={cloneBoardModalOpen}
        idToPass={board!.id}
        confirmButton={handleCloneBoard}
        closeOnConfirm={false}
        understandCheck={false}
        loadingConfirm={cloneBoardMutationRes.loading}
      />
      <ButtonGroup>
        <PinBoardButton />
        <CopyBoardButton />
      </ButtonGroup>
      <SettingsMenu settings={settings} center={true} align={MenuAlign.RIGHT}>
        <ToolbarBaseButton id="toolbar-group-settings-icon" icon={<Cog6ToothIcon className="h-5 w-5 stroke-2" />} buttonRounding="full" label="Options" />
      </SettingsMenu>
    </div>
  );
};

const copyBoardLink = ({ boardId, teamId, orgId }: { boardId: number; teamId: number; orgId: number }) => {
  const boardLink = getBoardLink({ boardId, teamId, orgId });
  navigator.clipboard.writeText(boardLink);
  toast.success('Link successfully copied!');
  return boardLink;
};

export const getBoardLink = ({ boardId, teamId, orgId }: { boardId: number; teamId: number; orgId: number }) => {
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  const newUrl = window.location.protocol + '//' + window.location.host + AppRoutes.v3FullPath.boards + `/${boardId}?` + urlParams.toString();
  return newUrl;
};

const PinBoardButton = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const state = useBoardState();
  const dispatch = useBoardDispatch();

  const [togglePinBoard, togglePinBoardRes] = useTogglePinBoardMutation();
  async function handleTogglePin() {
    await togglePinBoard({
      variables: {
        teamId,
        boardId: state.board.id,
      },
      refetchQueries: ['GetBoards'],
      onCompleted() {
        const newPinStatus = !state.board?.isPinnedByUser;
        dispatch({ type: BoardActionTypes.SetPinned, payload: { isPinned: newPinStatus } });
      },
      onError(err) {
        toast.error('Failed to toggle board pin');
        console.error(err);
      },
    });
  }

  let icon = <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" />;
  if (state.board?.isPinnedByUser) icon = <BookmarkIconSolid className="h-5 w-5 self-center text-blueberry" id="unpin-group" />;

  return (
    <ToolbarBaseButton
      id="toolbar-pin-board-button"
      icon={icon}
      label={'Pin Board'}
      onClick={handleTogglePin}
      loading={togglePinBoardRes.loading}
      buttonRounding={'left'}
    />
  );
};

const CopyBoardButton = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const state = useBoardState();

  function handleCopyLink(e?: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e && e.stopPropagation();
    copyBoardLink({ boardId: state.board.id, teamId, orgId });
  }

  let icon = <LinkIcon className="h-5 w-5 self-center" />;

  return <ToolbarBaseButton id="toolbar-copy-board-link-button" icon={icon} label={'Copy Link'} onClick={handleCopyLink} buttonRounding={'right'} />;
};
