import { Link } from 'react-router-dom';
import Button, { ButtonVariant } from '../baseComponents/Button';
import DropDown from '../baseComponents/DropDown';
import Tippy from '@tippyjs/react';
import { classNames } from '../../v2/util';
import toast from 'react-hot-toast';
import YesCancelModal from './Modals/YesCancelModal';
import { useFilterHook } from '../hooks/FilterHook';
import { useEffect, useState } from 'react';
import { Chart_Bin_Type } from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useChartsPageHook } from '../hooks/ChartsPageHook';
import { useInView } from 'react-intersection-observer';
import { ChartBinTypes } from '../pages/ChartsPage';
import { LoadableCustomChartCard } from './CustomChartCard';
import { FilterManager } from '../sections/Filters/ManagerV2/FilterManager';
import { FilterManagerDisplayMode } from '../sections/Filters/FiltersUtil';

const pageSize = 6;

export const ChartListSection = ({ pageName }: { pageName: string }) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const filterHook = useFilterHook({ teamId, orgId, filterKey: 'chart' });
  const [binType, setBinType] = useState(Chart_Bin_Type.Dynamic);
  const chartsPageHook = useChartsPageHook({ teamId, orgId, filterInput: filterHook.filters, binType });
  const { ref, inView } = useInView({ rootMargin: '100px', threshold: 0 });
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);
  const [curDeleteChartId, setCurDeleteChartId] = useState<number>();

  const [curChartsAmount, setCurChartsAmount] = useState<number>(pageSize);

  useEffect(() => {
    if (inView) setCurChartsAmount((prev) => prev + pageSize);
  }, [inView]);

  useEffect(() => {
    setCurChartsAmount(pageSize);
  }, [filterHook.filters]);

  const deleteChart = () => {
    if (curDeleteChartId)
      chartsPageHook
        .deleteCustomChart(curDeleteChartId)
        .then(() => {
          toast.success('Chart deleted successfully');
        })
        .finally(() => {
          setDeleteChartModalOpen(false);
          setCurDeleteChartId(undefined);
        });
    else {
      setDeleteChartModalOpen(false);
      setCurDeleteChartId(undefined);
    }
  };

  return (
    <div className="flex flex-col">
      {deleteChartModalOpen && (
        <YesCancelModal
          text={'Are you sure you want to delete this chart?'}
          subtext={'This action cannot be undone.'}
          confirmText="Yes, delete"
          callbackModal={setDeleteChartModalOpen}
          modalOpen={deleteChartModalOpen}
          idToPass={curDeleteChartId}
          confirmButton={deleteChart}
          closeOnConfirm={false}
          understandCheck={false}
          loadingConfirm={chartsPageHook.loadingStatues.deletingChart}
        />
      )}
      <div className="flex flex-row justify-between gap-x-2">
        <div className={classNames('border-b border-gray-200 pb-2')}>
          <FilterManager pageName={pageName} filterHook={filterHook} dataTypeToFilter={'chart'} displayMode={FilterManagerDisplayMode.ChartsPage} />
        </div>
        <div className="flex flex-row gap-x-1">
          <Tippy theme="dark" content="Change the x-axis range">
            <div>
              <DropDown
                id="chart-bin-type-dropdown"
                selectedItem={ChartBinTypes.find((item) => item.value === binType)}
                dropDownData={ChartBinTypes}
                setSelectedItem={(item) => {
                  if (item.value) {
                    setBinType(item.value as Chart_Bin_Type);
                  }
                }}
              />
            </div>
          </Tippy>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col mt-2">
          <div className="justify-center flex">
            <Link to={'creator'}>
              <Button variant={ButtonVariant.Tertiary} text="Add new chart +" />
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 mt-4 gap-y-8">
          <>
            {chartsPageHook.teamCharts.length === 0 && !chartsPageHook.loadingStatues.loadingTeamCharts ? (
              <div className="flex flex-col col-span-2 items-center justify-center mt-16">
                <div className="text-2xl font-semibold text-blueberry">No charts found</div>
              </div>
            ) : null}
            {chartsPageHook.teamCharts &&
              chartsPageHook.teamCharts.slice(0, curChartsAmount).map((lightChart) => {
                
                return (
                  <div className="h-[20rem]">
                    <LoadableCustomChartCard
                      chartId={lightChart.chartId}
                      filterInput={filterHook.filters}
                      key={lightChart.chartId}
                      deleteChart={() => {
                        setCurDeleteChartId(lightChart.chartId);
                        setDeleteChartModalOpen(true);
                      }}
                      binType={binType}
                    />
                  </div>
                );
              })}
          </>

          {chartsPageHook.totalAmountOfCharts && curChartsAmount < chartsPageHook.totalAmountOfCharts ? (
            <div ref={ref} className="charts-page-scroll-ref mb-4"></div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
