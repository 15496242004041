import { PlusIcon } from '@heroicons/react/24/solid';
import { Routes } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import NewGroupModal from '../components/Modals/NewGroupModal';
import { Route } from 'react-router-dom';
import { classNames } from '../../v2/util';
import { Organization_Users_Role, Role } from '../../generated/graphql';
import { OrganizationPage } from './organization/OrganizationPage';
import YesCancelModal from '../components/Modals/YesCancelModal';
import { logEvent, Events } from '../../v2/AnalyticsUtil';
import UserContext from '../../v2/contexts/UserContext';
import { PageWrapper } from './PageWrapper';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../baseComponents/Button';
import AppContext from '../../v2/contexts/AppContext';
import { useOrganizationHook } from '../../v2/hooks/OrganizationHook';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../Routes';
import { UsersIcon } from '@heroicons/react/24/outline';
import { BaseCardWithHeader } from '../components/BaseCardWithHeader';
import Badge, { BadgeSize } from '../../baseComponents/Badge';
import DualToneModal from '../baseComponents/DualToneModal';

export const OrganizationsRouterPage = ({ pageName }: { pageName: string }) => {
  return (
    <Routes>
      <Route path={`/:orgId`} element={<OrganizationPage />} />
      <Route path={'/'} element={<OrganizationsList pageName={'Organizations'} />} />
    </Routes>
  );
};

const OrganizationsList = ({ pageName }: { pageName: string }) => {
  const { curTeamId: teamId, curOrgId: orgId, currentTeam, currentOrg, organizations } = useContext(AppContext);
  const { deleteOrg, addOrganization } = useOrganizationHook();
  const [currentOrgId, setCurrentOrgId] = useState<number>();
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);
  const [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState(false);

  const { user } = useContext(UserContext);
  const createNewOrganization = (name: string) => {
    addOrganization(name);
    setNewOrgModalOpen(false);
  };
  useEffect(() => {
    logEvent(Events.PageLoaded, { View_ID: teamId, Org_ID: orgId, View_Name: currentTeam?.name, Org_Name: currentOrg?.name, Page: pageName });
  }, []);
  const deleteOrganization = (currentOrgId: number) => {
    deleteOrg(currentOrgId);
    setDeleteOrgModalOpen(false);
  };

  return (
    <PageWrapper title="Organizations">
      {(organizations && organizations.length > 0) || user?.isUnwrapper ? (
        <>
          {newOrgModalOpen && (
            <NewGroupModal
              modalOpen={newOrgModalOpen}
              callbackModal={() => setNewOrgModalOpen(false)}
              confirmButton={(orgName: string, logoUrl: string, demoMode: string) => {
                createNewOrganization(orgName);
                setNewOrgModalOpen(false);
              }}
              type="Organization"
            />
          )}
          {deleteOrgModalOpen && (
            <YesCancelModal
              text={'Are you sure you want to delete this organization?'}
              subtext={'This action cannot be undone.'}
              confirmText="Yes, delete"
              callbackModal={setDeleteOrgModalOpen}
              modalOpen={deleteOrgModalOpen}
              idToPass={currentOrgId}
              confirmButton={() => deleteOrganization(currentOrgId!)}
              closeOnConfirm={false}
              understandCheck={true}
            />
          )}
          <h2 className="text-md mt-2 mb-4 text-gray-500">
            Below are all the organizations you belong to. Click into an organization to manage permissions and create new views of your data.
          </h2>
          <div className="grid grid-cols-3 gap-x-5 gap-y-3">
            {organizations &&
              organizations.map((org) => {
                return (
                  <OrganizationCard
                    key={org.id}
                    title={org.name}
                    orgId={org.id}
                    logoUrl={''}
                    onDeleteClick={() => {
                      setCurrentOrgId(org.id);
                      setDeleteOrgModalOpen(true);
                    }}
                    active={org.id === orgId}
                    isSSO={org.isManagedByIdentityPool}
                  />
                );
              })}
            {user?.isUnwrapper && (
              <Button
                text="Add new organization"
                size={ButtonSize.Large}
                expandHeight
                expandWidth
                onClick={() => setNewOrgModalOpen(true)}
                icon={<PlusIcon className="h-4 w-4" />}
              />
            )}
          </div>
        </>
      ) : (
        <h2 className="text-md mb-4 font-semibold text-blueberry">
          You are not part of any organizations. You can either ask for an invite from your company's organization admin, or contact Unwrap to create a new
          organization.
        </h2>
      )}
    </PageWrapper>
  );
};

const CurrentBadge = () => <div className="flex flex-row px-1 text-sm text-end rounded-md text-success border-success border">Current</div>;
const SSOBadge = () => <div className="flex flex-row px-1 text-sm text-end rounded-md text-gray-500 border-gray-500 border">SSO</div>;

interface OrganizationCardProps {
  title: string;
  logoUrl: string | null | undefined;
  orgId: number;
  onDeleteClick: () => void;
  active?: boolean;
  isSSO?: boolean;
}

const OrganizationCard = ({ title, logoUrl, orgId, onDeleteClick, active, isSSO }: OrganizationCardProps) => {
  return (
    <NavLink to={AppRoutes.v3FullPath.organizations + '/' + orgId}>
      <BaseCardWithHeader
        height="short"
        headerChildren={
          <>
            {active ? <CurrentBadge /> : null}
            {isSSO ? <SSOBadge /> : null}
          </>
        }
        bodyChildren={
          <div className="max-w-xl text-blueberry">
            <UsersIcon className="h-7 w-7 float-left mr-1" />
            <p className="text-xl 2xl:text-2xl">{title}</p>
          </div>
        }
      />
    </NavLink>
  );
};
