import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { useBoardState, useBoardDispatch } from '../../../../context/boardContext';
import { FilterInput, SavedFilterInput, useUpdateBoardFilterMutation } from '../../../../generated/graphql';
import { BoardActionTypes } from '../../../../reducers/boards/boardReducer';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { useIsMount } from '../../../../v2/util';
import Button, { ButtonVariant, ButtonSize, ButtonShape } from '../../../baseComponents/Button';
import { FilterHook, defaultEndDate } from '../../../hooks/FilterHook';
import { IFilter } from '../../../sections/Filters/FiltersTypes';
import { FilterManagerDisplayMode } from '../../../sections/Filters/FiltersUtil';
import { FilterManager } from '../../../sections/Filters/ManagerV2/FilterManager';
import { buildFilterInputFromSavedFilterInput } from '../../ChartsPage';
import toast from 'react-hot-toast';

export const BoardFilterSection = ({
  filterHook,
  leftSideUI, //UI shown to the left of the Filter, not related to filters.
}: {
  filterHook: FilterHook;
  leftSideUI?: JSX.Element;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const state = useBoardState();
  const dispatch = useBoardDispatch();
  const filterManagerRef = useRef<any>(null);
  const [sharedFiltersShown, setSharedFiltersShown] = useState<IFilter[]>([]);
  const [isEditingFilters, setIsEditingFilters] = useState(false); //True while the user applied filters and haven't saved them yet
  //Refs like this one allow us to handle variables without waiting for the next render.
  //They should be only used on specific cases that don't require state handling. This manages the display of the saving/discard filter buttons.
  const skipEffect = useRef(false);

  const [updateBoardFilter, updateBoardFilterMutationRes] = useUpdateBoardFilterMutation();
  async function handleSaveFilter() {
    await updateBoardFilter({
      variables: {
        teamId,
        boardId: state.board.id,
        filterInput: filterHook.filters,
      },
      onCompleted(data) {
        dispatch({ type: BoardActionTypes.SetFilterInput, payload: { filterInput: data.updateBoardFilter.filterInput } });
        setIsEditingFilters(false);
        toast.success('Filters saved');
      },
      onError(err) {
        toast.error('Failed to save filters');
        console.error(err);
      },
    });
  }

  const isFirstRender = useIsMount();
  /*  useEffect(() => {
    console.count('akeke');
    if (!isFirstRender) {
      if (skipEffect.current) {
        skipEffect.current = false;
      } else {
        setIsEditingFilters(true);
      }
    }
  }, [sharedFiltersShown]); */
  useEffect(() => {
    if (!isFirstRender) {
      const boardFilterInput = buildFilterInputFromSavedFilterInput(state.board.filterInput);
      const filtersEqual = areFiltersEqual(boardFilterInput, filterHook.filters);
      if (!filtersEqual) setIsEditingFilters(true);

      /* const boardFilterInput = buildFilterInputFromSavedFilterInput(state.board.filterInput);
      console.log(areFiltersEqual(boardFilterInput, filterHook.filters));
      // console.log(isSavedFilterInputEqualToFilter({ savedFilter: boardFilterInput, comparisonFilter: filterHook.filters }));
      if (skipEffect.current) {
        skipEffect.current = false;
      } else {
        setIsEditingFilters(true);
      } */
    }
  }, [filterHook.filters]);

  function handleDiscardFilterChanges() {
    const boardFilterInput = buildFilterInputFromSavedFilterInput(state.board.filterInput, filterHook.originalStartDate, defaultEndDate);
    skipEffect.current = true;
    filterHook.setFilters(boardFilterInput);
    filterManagerRef.current?.refreshUiFilters(boardFilterInput);
    setIsEditingFilters(false);
  }

  return (
    <div className="flex flex-col mt-5 gap-y-1">
      <div className="flex flex-row w-full items-center justify-between">
        {leftSideUI}
        <div className="flex-auto border-t-2 border-gray-300 mx-4"></div>
        <FilterManager
          filterButtonText="Filter"
          pageName={'Board Page'}
          filterHook={filterHook}
          dataTypeToFilter={'boardPage'}
          parentSetFiltersShown={(filters: IFilter[]) => {
            setSharedFiltersShown(filters);
          }}
          // This shows the fitlers despite the name of displayMode wtf...
          displayMode={FilterManagerDisplayMode.GroupPageNoFiltersShown}
          ref={filterManagerRef}
        />
      </div>
      <div className="flex flex-row items-center gap-x-2 justify-end">
        {/* // why is this needed */}
        <FilterManager
          filterButtonText="Filter"
          pageName={'Board Page'}
          filterHook={filterHook}
          dataTypeToFilter={'boardPage'}
          displayMode={FilterManagerDisplayMode.GroupPageOnlyFiltersShown}
          overridenFiltersShown={sharedFiltersShown}
          parentSetFiltersShown={(filters: IFilter[]) => {
            setSharedFiltersShown(filters);
          }}
        />
        {isEditingFilters ? (
          <div className="flex flex-row gap-x-1 items-center">
            <div className="flex flex-row px-2 py-1 hover:bg-silver cursor-pointer duration-150" onClick={handleDiscardFilterChanges}>
              <p className="text-gray-400 font-semibold text-xs">Discard Changes</p>
            </div>
            <Button
              variant={ButtonVariant.Tertiary}
              size={ButtonSize.XSmall}
              shape={ButtonShape.Pill}
              text="Save Filters"
              icon={<Icon icon="mdi:content-save" width="20" height="20" />}
              iconPosition="left"
              onClick={handleSaveFilter}
              loadingConfirm={updateBoardFilterMutationRes.loading}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

/**
 * This function lets us compare the board's filters to the new filters changed by the user.
 * With this we can decide if we should show the discard/save buttons or not.
 * Until we improve the FilterHook/Manager, we can't rely on the updates they send due to multiple initial re-renders sending multiple actions.
 * Also, the normalization/sorting here is to be able to compare SavedFilterInputs vs FilterInput. They have different # of props and typos...
 * @param prevFilters
 * @param nextFilters
 * @returns
 */
const areFiltersEqual = (prevFilters: FilterInput, nextFilters: FilterInput) => {
  //remove startDate and endDate
  const prevFiltersCopy = { ...prevFilters };
  const nextFiltersCopy = { ...nextFilters };
  delete prevFiltersCopy.startDate;
  delete prevFiltersCopy.endDate;
  delete nextFiltersCopy.startDate;
  delete nextFiltersCopy.endDate;

  const normalizedPrevFilters = normalizeFilters(prevFiltersCopy);
  const normalizedNextFilters = normalizeFilters(nextFiltersCopy);

  const allKeys = new Set([...Object.keys(normalizedPrevFilters), ...Object.keys(normalizedNextFilters)]);

  // Ensure both filter objects have all keys with sorted values
  allKeys.forEach((key) => {
    if (!(key in normalizedPrevFilters)) normalizedPrevFilters[key as keyof FilterInput] = null;
    if (!(key in normalizedNextFilters)) normalizedNextFilters[key as keyof FilterInput] = null;
  });

  const sortObjectKeys = (obj: any) => {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = obj[key];
        return sortedObj;
      }, {} as any);
  };

  const sortedPrevFilters = sortObjectKeys(normalizedPrevFilters);
  const sortedNextFilters = sortObjectKeys(normalizedNextFilters);

  return JSON.stringify(sortedPrevFilters) === JSON.stringify(sortedNextFilters);
};
const normalizeFilters = (filters: FilterInput) => {
  const normalizedFilters: Partial<FilterInput> = { ...filters };
  for (const key in normalizedFilters) {
    if (
      normalizedFilters[key as keyof FilterInput] === null ||
      normalizedFilters[key as keyof FilterInput] === undefined ||
      (Array.isArray(normalizedFilters[key as keyof FilterInput]) && (normalizedFilters[key as keyof FilterInput] as any[]).length === 0)
    ) {
      delete normalizedFilters[key as keyof FilterInput];
    }
  }
  return normalizedFilters;
};
