import { OrganizationFragment, OrganizationUser, OrganizationUserFragment, TeamDataFragment, Teams } from '../../generated/graphql';

export interface OrgInfoState {
  organization: OrganizationFragment;
}

export enum OrgInfoActionTypes {
  SetOrg,
  SetTitle,
  AddMember,
  RemoveMember,
  EditMember,
  EditMembers,
  AddView,
  RemoveView,
  EditView,
}

type SetOrgPayload = { organization: OrganizationFragment };
type SetTitlePayload = { title: string };
type AddMemberPayload = { member: OrganizationUserFragment };
type RemoveMemberPayload = { userEmail: string };
type EditMemberPayload = { userId: number; updatedMember: Partial<OrganizationUserFragment> };
type EditMembersPayload = { updates: { userId: number; updatedMember: Partial<OrganizationUserFragment> }[] };
type AddViewPayload = { view: TeamDataFragment };
type RemoveViewPayload = { viewId: number };
type EditViewPayload = { viewId: number; updatedView: TeamDataFragment };

export type OrgInfoAction =
  | { type: OrgInfoActionTypes.SetOrg; payload: SetOrgPayload }
  | { type: OrgInfoActionTypes.SetTitle; payload: SetTitlePayload }
  | { type: OrgInfoActionTypes.AddMember; payload: AddMemberPayload }
  | { type: OrgInfoActionTypes.RemoveMember; payload: RemoveMemberPayload }
  | { type: OrgInfoActionTypes.EditMember; payload: EditMemberPayload }
  | { type: OrgInfoActionTypes.AddView; payload: AddViewPayload }
  | { type: OrgInfoActionTypes.RemoveView; payload: RemoveViewPayload }
  | { type: OrgInfoActionTypes.EditView; payload: EditViewPayload }
  | { type: OrgInfoActionTypes.EditMembers; payload: EditMembersPayload };

export const OrgInfoReducer = (state: OrgInfoState, action: OrgInfoAction): OrgInfoState => {
  switch (action.type) {
    case OrgInfoActionTypes.SetOrg:
      return { ...state, organization: action.payload.organization };

    case OrgInfoActionTypes.SetTitle:
      return {
        ...state,
        organization: {
          ...state.organization,
          name: action.payload.title,
        },
      };

    case OrgInfoActionTypes.AddMember:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: [...state.organization.orgUser, action.payload.member],
        },
      };

    case OrgInfoActionTypes.RemoveMember:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: state.organization.orgUser.filter((member) => member.email !== action.payload.userEmail),
        },
      };

    case OrgInfoActionTypes.EditMember:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: state.organization.orgUser.map((member) =>
            member.user?.id === action.payload.userId ? { ...member, ...action.payload.updatedMember } : member
          ),
        },
      };

    case OrgInfoActionTypes.EditMembers:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: state.organization.orgUser.map((member) => {
            const update = action.payload.updates.find((update) => update.userId === member.user?.id);
            return update ? { ...member, ...update.updatedMember } : member;
          }),
        },
      };

    case OrgInfoActionTypes.AddView:
      return {
        ...state,
        organization: {
          ...state.organization,
          teams: [...state.organization.teams, action.payload.view],
        },
      };

    case OrgInfoActionTypes.RemoveView:
      return {
        ...state,
        organization: {
          ...state.organization,
          teams: state.organization.teams.filter((view) => view.id !== action.payload.viewId),
        },
      };

    case OrgInfoActionTypes.EditView:
      return {
        ...state,
        organization: {
          ...state.organization,
          teams: state.organization.teams.map((view) => (view.id === action.payload.viewId ? { ...view, ...action.payload.updatedView } : view)),
        },
      };

    default:
      throw new Error('Unhandled action type');
  }
};

//export const getRolesForUsersForTeamEtc = () => {}
