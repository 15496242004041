import { useEffect, useReducer } from 'react';
import {
  Chart_Bin_Type,
  DigestDataFragment,
  Digest_Group_Status,
  GetInsightsDocument,
  InsightLightFragment,
  Insight_Status,
  useUpdateInsightMutation,
} from '../../../../generated/graphql';
import MutateInsightModal from './MutateInsightModal';
import { getDropDownItemFromEnumSelection, getEnumValue } from '../../../../reducers/utilities/enumHandling';
import { RowLabel, RowWrapper } from '../../../baseComponents/RowWrapper';
import { useFilterHook } from '../../../hooks/FilterHook';
import { buildFilterInputFromSavedFilterInput } from '../../../pages/ChartsPage';
import { InsightAction, InsightReducer, InsightState } from '../../../../reducers/insights/insightReducer';
import { NOT_FOUND } from '../../../../reducers/utilities/consts';

interface EditInsightModalProps {
  isModalOpen: boolean;
  setOpen: (state: boolean) => void;
  insight: InsightLightFragment;
  digest: DigestDataFragment;
}
const EditInsightModal = (props: EditInsightModalProps) => {
  const { insight, digest, isModalOpen } = props;
  const [miState, dispatch] = useReducer(InsightReducer, { insight, digest }, getInitialMutateInsightState);
  const [updateInsight, _] = useUpdateInsightMutation({});

  // does this properly update?
  const filterHook = useFilterHook({
    teamId: insight.team.id,
    orgId: insight.team.orgId!,
    startingFilterInput: getFiltersFromInsight(insight),
  });

  useEffect(() => {
    filterHook.setFilters(getFiltersFromInsight(insight));
    // you need to dispatch an initial state here.
    dispatch({ type: InsightAction.SetInitialState, payload: getInitialMutateInsightState({ insight, digest }) });
  }, [insight]);

  // save - you want to save the insight and update the insight in the list of insights along with the chart of the insight...
  // can we refetch the list and chart for the given insight?

  const saveInsight = () => {
    dispatch({ type: InsightAction.SetLoading, payload: { loading: true } });
    updateInsight({
      variables: {
        entryIds: miState.previewEntries.filter((e) => e.isSelected).map((e) => e.entry.id),
        expirationDate: miState.expiration,
        feedbackStatus: getEnumValue(Digest_Group_Status, miState.feedbackStatus),
        filterInput: filterHook.filters,
        insightId: insight.id,
        insightStatus: getEnumValue(Insight_Status, miState.insightStatus),
        text: miState.text,
        title: miState.title,
        chartBin: getEnumValue(Chart_Bin_Type, miState.chartBin),
        order: miState.order,
        excludeFromDigest: miState.excludeFromDigest,
      },
      // reload the insights list. This wont automatically cause the chart to redraw because the chart is fetched only from the insight.id which the backend
      // uses to get all information to render the chart from
      // this is the query that we need to force refetch... or stuff data into...
      // const { data, loading } = useGetInsightWithChartQuery({ variables: { insightId: insight.id } });
      refetchQueries: [{ query: GetInsightsDocument, variables: { digestId: digest.id } }],
      awaitRefetchQueries: true, // wait for the refetch response before returning.
      onCompleted: () => {
        props.setOpen(false);
        dispatch({ type: InsightAction.SetLoading, payload: { loading: false } });
      },
    });
  };

  return (
    <MutateInsightModal
      isModalOpen={isModalOpen}
      setOpen={props.setOpen}
      teamId={insight.team.id}
      miState={miState}
      dispatch={dispatch}
      saveInsightCloseModal={saveInsight}
      collection={insight.collection}
      filterHook={filterHook}
      filterInput={filterHook.filters}
      digestDisplay={<DigestDisplaySection miState={miState} />}
    />
  );
};

export const getFiltersFromInsight = (insight: InsightLightFragment) => {
  return {
    ...buildFilterInputFromSavedFilterInput(
      insight.filterInput,
      insight.filterInput.startDate ? new Date(insight.filterInput.startDate) : undefined,
      insight.filterInput.endDate ? new Date(insight.filterInput.endDate) : undefined
    ),
  };
};

const DigestDisplaySection = (props: { miState: InsightState }) => {
  return (
    <RowWrapper>
      <RowLabel label="Digest" />
      <h1>{props.miState.chosenDigest.name}</h1>
    </RowWrapper>
  );
};

const getInitialMutateInsightState = (props: { insight: InsightLightFragment; digest: DigestDataFragment }): InsightState => {
  const { insight, digest } = props;
  return {
    digestOptions: [],
    chosenDigest: { name: `${digest.digestSeries.title}-${new Date(digest.dateCreated).toDateString()}`, id: digest.id },
    collection: insight.collection,
    previewEntries: insight.entries.map((entry) => {
      return { isSelected: true, entry };
    }),
    canSelectMoreEntries: insight.entries.length < 3,
    expiration: new Date(insight.expirationDate),
    feedbackStatus: getDropDownItemFromEnumSelection(Digest_Group_Status, insight.feedbackStatus),
    insightStatus: getDropDownItemFromEnumSelection(Insight_Status, insight.insightStatus),
    chartBin: getDropDownItemFromEnumSelection(Chart_Bin_Type, insight.chartBin),
    title: insight.title,
    text: insight.text,
    teamId: insight.team.id,
    orgId: insight.team.orgId!,
    createSaveLoading: false,
    checkingExistingInsightLoading: false,
    editingMode: true,
    id: insight.id,
    order: insight.order,
    excludeFromDigest: insight.excludeFromDigest,
  };
};

export default EditInsightModal;
