import { forwardRef, useImperativeHandle, useState } from 'react';
import { DatePicker } from '../../../../baseComponents/DatePicker';
import moment from 'moment';
import { FilterInput, Date_Window } from '../../../../../generated/graphql';
import { IDropDownItem } from '../../../../baseComponents/DropDown';
import { HorizontalDateSelectorOption } from '../../../../components/HorizontalDateSelector';
import { VirtualizedComboBox } from '../../../../components/VirtualizedComboBox';
import { FilterHook } from '../../../../hooks/FilterHook';
import { IFilter } from '../../FiltersTypes';
import { DateOptions } from '../../FiltersUtil';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';

interface DateRangeDropdownProps {
  options: HorizontalDateSelectorOption[];
  onChooseDate: (newStartDate?: Date, newEndDate?: Date, option?: HorizontalDateSelectorOption) => void;
  defaultSelect?: HorizontalDateSelectorOption;
  dateChange?: (startDate: Date, endDate: Date) => void;
}

interface DateSectionProps {
  filters: FilterInput;
  dateOptions: HorizontalDateSelectorOption[];
  updateClustersDates: (newStartDate?: Date, newEndDate?: Date) => void;
  dateSelectorRef: React.MutableRefObject<any>;
  hook: FilterHook;
  filtersShown: IFilter[];
  queryStringAppliesToGroupTitle?: boolean;
}

export const DateFilters = ({
  filters,
  dateOptions,
  updateClustersDates,
  dateSelectorRef,
  hook,
  filtersShown,
  queryStringAppliesToGroupTitle,
}: DateSectionProps) => {
  return (
    <div id="date-selector" className="flex flex-row gap-x-1">
      <div className="flex flex-row items-center bg-silver rounded-2xl select-none pl-4">
        <div className="flex flex-row items-center gap-x-">
          <p className="text-gray-500">From</p>
          <DatePicker
            date={filters.startDate}
            onChange={(dt: Date) => {
              updateClustersDates(dt, undefined);
              dateSelectorRef.current?.updateSelectedOption(dt, filters.endDate);
            }}
          />
        </div>
        <div className="flex flex-row items-center gap-x-">
          <p className="text-gray-500">to</p>
          <DatePicker
            date={filters.endDate}
            onChange={(dt: Date) => {
              updateClustersDates(undefined, dt);
              dateSelectorRef.current?.updateSelectedOption(filters.startDate, dt);
            }}
          />
        </div>
      </div>
      <div className="w-[6rem]">
        <DateRangeDropdown
          onChooseDate={(newStartDate: Date | undefined, newEndDate: Date | undefined) => {
            hook.updateFilterDates(newStartDate, newEndDate, filtersShown, queryStringAppliesToGroupTitle);
          }}
          options={dateOptions}
          defaultSelect={getDateOptionFromDateRange(filters.startDate, filters.endDate)}
          ref={dateSelectorRef}
        />
      </div>
    </div>
  );
};

export const DateRangeDropdown = forwardRef(({ options, onChooseDate, defaultSelect, dateChange }: DateRangeDropdownProps, ref) => {
  const [selectedOption, setSelectedOption] = useState<HorizontalDateSelectorOption | undefined>(defaultSelect);

  const handleOptionClick = (option: HorizontalDateSelectorOption) => {
    setSelectedOption(option);
    onChooseDate(option.startDate, option.endDate, option);
  };

  useImperativeHandle(ref, () => ({
    //This is used by the Date Picker to update the selected option (including none).
    updateSelectedOption: (startDate: Date, endDate: Date) => {
      const newOption = options.find((option) => {
        return moment(option.startDate).isSame(moment(startDate), 'day') && moment(option.endDate).isSame(moment(endDate), 'day');
      });
      setSelectedOption(newOption);
    },
    //Change to default
    reset: () => {
      setSelectedOption(options[2]);
    },
    //Set by date_window
    setOptionByDateWindow: (date_window: Date_Window) => {
      const newOption = options.find((option) => option.date_window === date_window);
      if (newOption) {
        setSelectedOption(newOption);
        onChooseDate(newOption.startDate, newOption.endDate);
      } else {
        console.error('Invalid date_window');
      }
    },
    clearSelectedWindow: () => {
      setSelectedOption(undefined);
    },
  }));

  const comboBoxData: IDropDownItem[] = options.map((option, idx) => {
    return {
      name: option.name,
      id: idx,
    };
  });

  return (
    <VirtualizedComboBox
      comboBoxData={comboBoxData}
      disableClear
      disableAlphabeticalSort
      grayStyle
      setSelectedItem={(item) => handleOptionClick(options.find((option) => option.name === item!.name)!)}
      selectedItem={selectedOption ? { name: selectedOption.name, id: options.indexOf(selectedOption) } : { name: 'Custom', id: -1 }}
    />
  );
});

const getDateOptionFromDateRange = (startDate: Date | undefined, endDate: Date | undefined): HorizontalDateSelectorOption | undefined => {
  //This checks if the date range is one of the default options, and if so, returns it.
  //If both dates are undefined, it should choose a default option, in this case we'll choose 90D.
  if (!startDate && !endDate) return DateOptions.find((option) => option.name === '90D');
  return DateOptions.find((option) => {
    return (
      option.startDate.getTime() === moment(startDate).startOf('day').toDate().getTime() &&
      option.endDate.getTime() === moment(endDate).endOf('day').toDate().getTime()
    );
  });
};
