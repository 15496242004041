import Tippy from '@tippyjs/react';
import { classNames } from '../../../../../v2/util';
import AdjustableLoadingIcon from '../../../../../baseComponents/AdjustableLoadingIcon';

type ToolbarButtonProps = {
  id: string;
  icon: JSX.Element;
  label?: string;
  loading?: boolean;
  onClick?: (props: any) => void;
  buttonRounding?: 'left' | 'right' | 'full';
};

export const ToolbarBaseButton = ({ id, icon, label, loading, onClick, buttonRounding }: ToolbarButtonProps) => {
  return (
    <Tippy content={label} disabled={!label} delay={0} duration={[400, 100]}>
      <div
        id={id}
        data-testid={id}
        role="button"
        className={classNames(
          buttonRounding === 'left' ? 'rounded-l-xl' : '',
          buttonRounding === 'right' ? 'rounded-r-xl' : '',
          buttonRounding === 'full' ? 'rounded-full' : '',
          'bg-silver text-blueberry flex justify-center items-center cursor-pointer h-8 w-10 hover:bg-silver-darker duration-150 hover:shadow-sm focus:outline-none'
        )}
        onClick={onClick}
      >
        {loading ? <AdjustableLoadingIcon width={4} height={4} color="white" /> : icon}
      </div>
    </Tippy>
  );
};
