import { useContext } from 'react';
import InlineEditableTextArea from '../../../../baseComponents/InlineEditableTextArea';
import { useOrgInfoState, useOrgInfoDispatch } from '../../../../context/orgInfoContext';
import { Organization_Users_Role } from '../../../../generated/graphql';
import { OrgInfoActionTypes } from '../../../../reducers/orgInfo/orgInfoReducer';
import UserContext from '../../../../v2/contexts/UserContext';
import { useOrganizationHook } from '../../../../v2/hooks/OrganizationHook';
import toast from 'react-hot-toast';

export const OrgTitleSection = () => {
  const { organization } = useOrgInfoState();
  const dispatch = useOrgInfoDispatch();
  const orgHook = useOrganizationHook();

  const onEditNameComplete = async (name: string) => {
    dispatch({ type: OrgInfoActionTypes.SetTitle, payload: { title: name } });
    await orgHook.editOrganization(name); //Updates the AppContext used everywhere.
    toast.success('Organization name updated');
  };

  return (
    <div className="flex flex-row items-center justify-start gap-x-2 text-blueberry py-3">
      <h1 className="text-3xl font-semibold">Settings:</h1>
      <div className="w-96">
        <InlineEditableTextArea
          value={organization.name}
          onEditComplete={onEditNameComplete}
          editable
          ignorePadding
          additionalClassNames={
            'h-auto font-semibold focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
          }
          textClassName="text-3xl text-gray-500 font-semibold"
        />
      </div>
    </div>
  );
};
