import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { OrgInfoAction, OrgInfoReducer, OrgInfoState } from '../reducers/orgInfo/orgInfoReducer';
import { PermissionsAction, PermissionsInitialState, PermissionsReducer, PermissionsState } from '../reducers/orgInfo/permissions/permissionsReducer';

const PermissionsStateContext = createContext<PermissionsState | undefined>(undefined);
const PermissionsDispatchContext = createContext<Dispatch<PermissionsAction> | undefined>(undefined);

export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(PermissionsReducer, PermissionsInitialState);
  return (
    <PermissionsStateContext.Provider value={state}>
      <PermissionsDispatchContext.Provider value={dispatch}>{children}</PermissionsDispatchContext.Provider>
    </PermissionsStateContext.Provider>
  );
};

export const usePermissionsState = () => {
  const context = useContext(PermissionsStateContext);
  if (context === undefined) throw new Error('usePermissionsState must be used within a PermissionsProvider');

  return context;
};

export const usePermissionsDispatch = () => {
  const context = useContext(PermissionsDispatchContext);
  if (context === undefined) throw new Error('usePermissionsDispatch must be used within an PermissionsProvider');

  return context;
};
