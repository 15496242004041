import { EllipsisVerticalIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useContext, useState } from 'react';
import { useOrgInfoDispatch, useOrgInfoState } from '../../../../context/orgInfoContext';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import UserContext from '../../../../v2/contexts/UserContext';
import { classNames } from '../../../../v2/util';
import Button, { ButtonVariant, ButtonShape, ButtonSize } from '../../../baseComponents/Button';
import SearchInput from '../../../baseComponents/SearchInput';
import { InviteMemberModal } from '../../../components/Modals/InviteMemberModal';
import {
  Domain,
  Organization_Users_Role,
  OrganizationUserFragment,
  Role,
  Role_Attachments,
  useRemoveOrganizationUserMutation,
} from '../../../../generated/graphql';
import SettingsMenu, { ISettingsItem } from '../../../baseComponents/SettingsMenu';
import { EditMemberModal } from '../../../components/Modals/EditMemberModal';
import toast from 'react-hot-toast';
import { OrgInfoActionTypes } from '../../../../reducers/orgInfo/orgInfoReducer';
import { sortOrgUsersByName } from '../../../../utilities';
import { DefaultRoleModal } from '../../../components/Modals/DefaultRoleModal';
import { Cog6ToothIcon, KeyIcon } from '@heroicons/react/24/outline';

export const OrgMembersSection = () => {
  const { curOrgId } = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  const { organization } = useOrgInfoState();

  const [searchInput, setSearchInput] = useState('');
  const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
  const [defaultRoleModalOpen, setDefaultRoleModalOpen] = useState(false);

  const filteredMembers =
    sortOrgUsersByName(
      organization?.orgUser
        ?.filter((member) => {
          if (searchInput === '') return true;

          const fullName = `${member.user?.firstName || ''} ${member.user?.lastName || ''}`.toLowerCase();
          const email = member.email.toLowerCase();
          const search = searchInput.toLowerCase();

          return email.includes(search) || fullName.includes(search);
        })
        ?.filter((member) => {
          if (curOrgId === 254) return false; //Hide hundreds of members on Playwright Org
          if (!user?.isUnwrapper && member.email.includes('@unwrap.ai')) return false; //Hide Unwrap members for non-Unwrap users
          return true;
        })
    ) || [];

  const settingsItems: ISettingsItem[] = [
    { id: 0, group: 'actions', name: 'Set default role', icon: <KeyIcon className="w-5 h-5 text-blueberry" />, onClick: () => setDefaultRoleModalOpen(true) },
  ];
  return (
    <div className="flex flex-col gap-y-4 w-full">
      {inviteMemberModalOpen ? <InviteMemberModal modalOpen={inviteMemberModalOpen} callbackModal={() => setInviteMemberModalOpen(false)} /> : null}
      {defaultRoleModalOpen ? (
        <DefaultRoleModal
          views={organization.teams}
          user={organization.orgUser[0]}
          modalOpen={defaultRoleModalOpen}
          callbackModal={() => setDefaultRoleModalOpen(false)}
        />
      ) : null}
      <div className="flex flex-row justify-between text-blueberry items-center">
        <h1 className="text-2xl font-semibold">Members</h1>
        <div className="flex-auto border-t-2 border-gray-200 mx-4"></div>
        <div className="flex flex-row gap-x-3">
          {!organization.isManagedByIdentityPool ? (
            <Button
              variant={ButtonVariant.Bordered}
              shape={ButtonShape.Pill}
              size={ButtonSize.Small}
              text="Invite members"
              onClick={() => setInviteMemberModalOpen(true)}
            ></Button>
          ) : (
            <SettingsMenu center settings={settingsItems}>
              <Button variant={ButtonVariant.Bordered} shape={ButtonShape.Circle} size={ButtonSize.Small} icon={<Cog6ToothIcon className="w-5 h-5" />}></Button>
            </SettingsMenu>
          )}
        </div>
      </div>
      <div className="flex">
        <SearchInput
          noPadding
          rounded="md"
          placeholder={`Browse ${organization.orgUser.length} members...`}
          onSearch={() => {}}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          onClear={() => {
            setSearchInput('');
          }}
        />
      </div>
      <div className="flex flex-col scroll-shadows overflow-y-auto max-h-[24rem] divide-y divide-gray-200 border-b-2 border-gray-200">
        {filteredMembers.length > 0 ? (
          filteredMembers.map((member) => <MemberRow user={member} key={member.email} />)
        ) : (
          <p className="col-span-3 text-center font-normal text-blueberry py-2">No users match the search input</p>
        )}
      </div>

      {organization.isManagedByIdentityPool ? (
        <div className={`rounded-xl bg-raspberry text-white p-4`}>
          <p>Membership for this organization is managed through SSO. Please contact your system administrator to add or remove members.</p>
        </div>
      ) : null}
    </div>
  );
};

const MemberRow = ({ user }: { user: OrganizationUserFragment }) => {
  const { user: currentUser } = useContext(UserContext);
  const { organization } = useOrgInfoState();
  const dispatch = useOrgInfoDispatch();
  const [editMemberModalOpen, setEditMemberModalOpen] = useState(false);

  const [removeUser, removeUserMutationRes] = useRemoveOrganizationUserMutation();
  const handleRemoveUser = async () => {
    const toastId = toast.loading('Removing user...');
    await removeUser({
      variables: {
        orgId: organization.id,
        userToDeleteEmail: user.email,
      },
      onCompleted: () => {
        toast.dismiss(toastId);
        toast.success('User removed successfully!');
        dispatch({ type: OrgInfoActionTypes.RemoveMember, payload: { userEmail: user.email } });
      },
      onError: (error) => {
        toast.error(error.message);
        toast.dismiss(toastId);
      },
    });
  };
  const isCurrentUserOrgAdmin = organization.orgUser
    .find((orgUser) => orgUser.email.toLowerCase() === currentUser?.email.toLowerCase())
    ?.user?.roles.some((role) => role.domainId === organization.id && role.domain === Domain.Org && role.role === Role.Admin);

  const settingsItems: ISettingsItem[] = [];
  if (user.user) {
    //Only display Role editing on confirmed users
    settingsItems.unshift({
      id: 0,
      group: 'actions',
      name: 'Edit',
      icon: <UsersIcon className="w-5 h-5 text-blueberry" />,
      onClick: () => setEditMemberModalOpen(true),
    });
  }
  if (user.user?.email !== currentUser?.email && !organization.isManagedByIdentityPool) {
    settingsItems.push({
      id: 1,
      group: 'delete',
      name: 'Remove',
      icon: <XMarkIcon className="w-5 h-5 text-failure" />,
      textColor: 'failure',
      onClick: handleRemoveUser,
    });
  }

  return (
    <div id="org-member-row" className="flex flex-row items-center justify-end py-3 gap-x-3 w-full text-blueberry">
      {editMemberModalOpen ? <EditMemberModal user={user} modalOpen={editMemberModalOpen} callbackModal={() => setEditMemberModalOpen(false)} /> : null}
      <p className={classNames(!user.confirmed ? 'text-xs xl:text-sm text-gray-500' : ' text-sm xl:text-base font-semibold ', 'flex-1 basis-1/3 truncate')}>
        {!user.confirmed ? user?.email : user.user?.firstName + ' ' + user.user?.lastName}
      </p>
      <p title={user?.email} className="flex-1 basis-1/3 text-xxs xl:text-xs text-gray-500 truncate">
        {user.confirmed ? user?.email : null}
      </p>
      <p title={user?.email} className="flex-1 basis-1/4 text-xxs xl:text-xs text-gray-500 truncate">
        {organization.orgUser
          .find((orgUser) => orgUser.email.toLowerCase() === user.email.toLowerCase())
          ?.user?.roles.some((role) => role.domain === Domain.Org && role.domainId === organization.id && role.role === Role.Admin)
          ? 'Admin'
          : 'Member'}
      </p>
      <div className="flex-1 basis-1/12">{!user.confirmed ? <InvitedBadge /> : null}</div>
      {user && isCurrentUserOrgAdmin ? (
        <div className="flex-shrink-0 mr-3">
          <SettingsMenu settings={settingsItems} center>
            <Button
              variant={ButtonVariant.IconRawWithHover}
              shape={ButtonShape.Circle}
              size={ButtonSize.Small}
              icon={<EllipsisVerticalIcon className="h-5 w-5" />}
            />
          </SettingsMenu>
        </div>
      ) : null}
    </div>
  );
};

const InvitedBadge = () => (
  <div className="flex w-min flex-row px-1 text-xs font-semibold text-end rounded-md text-gray-500 border-gray-500 border-[1.5px]">Invited</div>
);
