import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useContext, useState } from 'react';
import { useOrgInfoState } from '../../../../context/orgInfoContext';
import { OrganizationUserFragment, TeamDataFragment } from '../../../../generated/graphql';
import AppContext, { OrgTeamWithInfo, useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import UserContext from '../../../../v2/contexts/UserContext';
import { ifUnwrapperShowId } from '../../../../v2/util';
import Button, { ButtonVariant, ButtonShape, ButtonSize } from '../../../baseComponents/Button';
import SettingsMenu, { ISettingsItem } from '../../../baseComponents/SettingsMenu';
import { isOrgUserAdmin } from '../util';
import { PencilSquareIcon, PresentationChartLineIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useOrganizationHook } from '../../../../v2/hooks/OrganizationHook';
import YesCancelModal from '../../../components/Modals/YesCancelModal';
import { EditViewModal } from '../../../components/Modals/EditViewModal';
import { AppRoutes } from '../../../../Routes';
import { useNavigate } from 'react-router-dom';

export const OrgViewsSection = () => {
  const { organization } = useOrgInfoState();
  const { user } = useContext(UserContext);

  const [newViewModalOpen, setNewViewModalOpen] = useState(false);

  const filteredMembers: OrganizationUserFragment[] =
    organization.orgUser.filter((member) => {
      if (organization.id === 254) return false; //Hide hundreds of members on Playwright Org
      if (!user?.isUnwrapper && member.email.includes('@unwrap.ai')) return false; //Hide Unwrap members for non-Unwrap users
      return true;
    }) || [];

  return (
    <div className="flex flex-col gap-y-2 w-full">
      {newViewModalOpen ? <EditViewModal users={filteredMembers} modalOpen={newViewModalOpen} callbackModal={() => setNewViewModalOpen(false)} /> : null}
      <div className="flex flex-row justify-between text-blueberry items-center mb-2">
        <h1 className="text-2xl font-semibold">Views</h1>
        <div className="flex-auto border-t-2 border-gray-200 mx-4"></div>
        <Button
          variant={ButtonVariant.Bordered}
          shape={ButtonShape.Pill}
          size={ButtonSize.Small}
          text="Create view +"
          onClick={() => setNewViewModalOpen(true)}
        ></Button>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-5 gap-y-2 xl:gap-y-3">
        {organization.teams.map((team, index) => (
          <ViewBadge key={index} team={team} />
        ))}
      </div>
    </div>
  );
};

const ViewBadge = ({ team }: { team: TeamDataFragment }) => {
  const { user } = useContext(UserContext);
  const { setCurTeamId } = useValidTeamAppContext();
  const { organization } = useOrgInfoState();

  const { deleteTeam } = useOrganizationHook();
  const navigate = useNavigate();

  const [deleteTeamModalOpen, setDeleteTeamModalOpen] = useState(false);
  const [deletingTeamLoading, setDeletingTeamLoading] = useState<boolean>(false);

  const filteredMembers: OrganizationUserFragment[] =
    organization.orgUser.filter((member) => {
      if (organization.id === 254) return false; //Hide hundreds of members on Playwright Org
      if (!user?.isUnwrapper && member.email.includes('@unwrap.ai')) return false; //Hide Unwrap members for non-Unwrap users
      return true;
    }) || [];

  const [editViewModalOpen, setEditViewModalOpen] = useState(false);
  const settingsItems: ISettingsItem[] = [
    { id: 0, group: 'actions', name: 'Edit', icon: <PencilSquareIcon className="w-5 h-5 text-blueberry" />, onClick: () => setEditViewModalOpen(true) },
    {
      id: 1,
      group: 'delete',
      name: 'Remove',
      icon: <TrashIcon className="w-5 h-5 text-failure" />,
      textColor: 'failure',
      onClick: () => setDeleteTeamModalOpen(true),
    },
  ];

  const handleDeleteTeam = async () => {
    setDeletingTeamLoading(true);
    await deleteTeam(team.id);
    setDeletingTeamLoading(false);
    setDeleteTeamModalOpen(false);
  };

  return (
    <div
      className="flex flex-row justify-between gap-x-3 items-center rounded-lg text-blueberry bg-silver hover:bg-silver-darker duration-150 hover:cursor-pointer px-5 py-4"
      onClick={() => {
        setCurTeamId(team.id);
        navigate(AppRoutes.v3FullPath.home);
      }}
    >
      {editViewModalOpen ? (
        <EditViewModal view={team} users={filteredMembers} modalOpen={editViewModalOpen} callbackModal={() => setEditViewModalOpen(false)} />
      ) : null}
      <YesCancelModal
        modalOpen={deleteTeamModalOpen}
        callbackModal={setDeleteTeamModalOpen}
        idToPass={team.id}
        text={'Are you sure you want to delete this view?'}
        subtext={'This action cannot be undone. All data will be deleted.'}
        closeOnConfirm={false}
        understandCheck={true}
        confirmButton={handleDeleteTeam}
        confirmText={'Yes, delete'}
        loadingConfirm={deletingTeamLoading}
      />
      <div className="flex flex-row items-center gap-x-2">
        {team.logoUrl ? <img src={team.logoUrl} className="h-6 w-6" /> : <PresentationChartLineIcon className="h-5 w-5" />}
        <p className="font-semibold line-clamp-1">{team.name}</p>
        <p className="text-xs">{ifUnwrapperShowId(user, team.id)}</p>
      </div>
      {user && isOrgUserAdmin({ organization, user }) ? (
        <div className="flex flex-row gap-x-2 items-center flex-shrink-0"
        onClick={(e) => e.stopPropagation()}>
          <SettingsMenu settings={settingsItems} center>
            <Button
              variant={ButtonVariant.IconRawWithHover}
              shape={ButtonShape.Circle}
              size={ButtonSize.Small}
              icon={<EllipsisVerticalIcon className="h-5 w-5" />}
            />
          </SettingsMenu>
        </div>
      ) : null}
    </div>
  );
};
